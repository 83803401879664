import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
	StyledNavBar,
	StyledNavLink,
	Icon,
	Logo,
	Circle,
	MobileLogo,
	LogoutIcon,
} from "./styles";
import { UserContext } from "../../Providers/user-provider";
import { useTheme } from "styled-components";
import { useQuery, useReactiveVar } from "@apollo/client";
import Backdrop from "../Backdrop/back-drop.component";
import {
	removeAccessToken,
	removeIdToken,
	removeTokenExpiry,
	removeUserId,
} from "../../helpers/functions";
import ReactTooltip from "react-tooltip";
import { GET_ALL_USER_INBOXES } from "./query";
import {
	$selectedFolder,
	$selectedConversation,
	$selectedInbox,
	$conversationFolderFilter,
} from "../../store";
import client from "../../Apollo";
import ReactGA from "react-ga4";

const Navbar = (props) => {
	const theme = useTheme();
	const userContext = useContext(UserContext);
	const { loading, data } = useQuery(GET_ALL_USER_INBOXES, {
		variables: {
			userId: localStorage.getItem("user_id"),
		},
	});

	const history = useHistory();
	const selectedInbox = useReactiveVar($selectedInbox);
	const user = useContext(UserContext);

	const logout = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}oauth/token`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
					credentials: "include",
				}
			);
			client.clearStore();
			removeAccessToken();
			removeIdToken();
			removeTokenExpiry();
			removeUserId();
			ReactGA.event({
				category: "Authentication",
				action: "SuccessfulLogout",
			});
			history.push("/login");
		} catch (error) {
			console.log("err", error);
		}
	};

	if (!loading) {
		return (
			<>
				<Backdrop onClick={props.close} visible={props.visible} />
				<StyledNavBar {...props} id="side-nav">
					{theme.images.topleftIcon !== undefined &&
					theme.images.topleftIcon ? (
						<Logo className="sidebar-brand-text">
							<img
								style={{ width: "35px", height: "35px" }}
								src={theme.images.topleftIcon}
							></img>
						</Logo>
					) : (
						<div
							style={{
								height: "50px",
								width: "40px",
								clear: "both",
							}}
						></div>
					)}
					<MobileLogo className="sidebar-brand-text">
						<Circle></Circle>
						MSGBOX
					</MobileLogo>
					<ul>
						{data && (
							<li
								key="1"
								onClick={() => {
									localStorage.setItem(
										"selected_inbox",
										data?.msgbox_UserInbox[0]?.Inbox
											?.InboxId || 0
									);
									$selectedConversation(-1);
									$selectedFolder(
										data?.msgbox_UserInbox[0]?.Inbox
											?.Folders[0]?.FolderId || -1
									);
									$conversationFolderFilter(
										data?.msgbox_UserInbox[0]?.Inbox
											?.Folders[0]?.Name
									);
									$selectedInbox(
										data?.msgbox_UserInbox[0]?.Inbox
											?.InboxId || 0
									);
								}}
							>
								<StyledNavLink
									activeClassName="selected"
									to="/inbox"
									data-tip="Inboxes"
									data-for="2"
								>
									<Icon className="fas fa-envelope"></Icon>
								</StyledNavLink>
							</li>
						)}

						<li data-tip="Contacts" data-for="2">
							<StyledNavLink
								to="/contacts"
								activeClassName="selected"
							>
								<Icon className="fas fa-address-book"></Icon>
							</StyledNavLink>
						</li>
						<li
							data-for="2"
							data-tip="Broadcast"
							onClick={props.close}
						>
							<StyledNavLink
								to="/broadcast"
								activeClassName="selected"
							>
								<Icon className="fas fa-podcast"></Icon>
							</StyledNavLink>
						</li>

						{user.permissions.canViewAutomations && (
							<li
								data-for="2"
								data-tip="Automations"
								onClick={props.close}
							>
								<StyledNavLink
									to="/automations"
									activeClassName="selected"
								>
									<Icon className="fas fa-lightbulb"></Icon>
								</StyledNavLink>
							</li>
						)}
						{user.permissions.canViewAnalytics && (
							<li
								data-for="2"
								data-tip="Analytics"
								onClick={props.close}
							>
								<StyledNavLink
									to="/analytics"
									activeClassName="selected"
								>
									<Icon className="fas fa-chart-line"></Icon>
								</StyledNavLink>
							</li>
						)}
					</ul>
					<LogoutIcon
						onClick={logout}
						className="fas fa-sign-out-alt"
						data-tip="Logout"
						data-for="2"
					/>
				</StyledNavBar>
				<ReactTooltip id="2" effect="solid" className="tooltip" />
			</>
		);
	} else return null;
};

export default Navbar;
