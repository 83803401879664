import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useHistory } from "react-router";

import { PageHeader } from "../../Styles/global";
import { SettingsContent } from "./styles";

import Back from "../../Components/Back/back.component";
import Profile from "../../Components/Profile/profile.component";
import SettingsSideNav from "../../Components/SettingsSideNav/settings-side-nav.component";
import TagsSettings from "../../Components/TagsSettings/tags-settings.component";
import OpeningHours from "../../Components/OpeningHours/opening-hours.component";
import Unsubscribe from "../../Components/Unsubscribe/unsubscribe.component";
import UserManagement from "../../Components/UserManagement/user-management.component";
import TeamManagement from "../../Components/TeamManagement/team-management.component";
import AutomationSettings from "../../Components/AutomationSettings/automation-settings.component";
import AdminRoute from "../../Components/AdminRoute";
import Permissions from "../../Components/Permissions/permissions.component";
import ChromeExtensionSettings from "../../Components/ChromeExtentionSettings/chromeExtentionSettings.component";
import PortalResponderSettings from "../../Components/PortalResponderSettings/portal-responder";
import NotificationSettings from "../../Components/NotificationsSettings/notifications-settings";
import WebhooksSettings from "../../Components/webhooks-settings/webhooks-settings";
import HeaderContainer from "../shared/components/header-container";
import InboxesSettings from "../../Components/AutomationSettings/inboxes-setttings/inboxes-settings";
import { useTheme } from "styled-components";

const Settings = ({ rootStore }) => {
	const history = useHistory();
	const location = useLocation();
	const [title, setTitle] = useState("");
	const theme = useTheme();

	useEffect(() => {
		switch (location.pathname) {
			case "/settings/profile":
				setTitle("Profile");
				break;

			case "/settings/opening-hours":
				setTitle("Opening Hours");
				break;

			case "/settings/tags":
				setTitle("Tags");
				break;

			case "/settings/subscribers":
				setTitle("Subscribers");
				break;

			case "/settings/users":
				setTitle("Users");
				break;

			case "/settings/teams":
				setTitle("Teams");
				break;

			case "/settings/automation":
				setTitle("Automation");
				break;

			case "/settings/permissions":
				setTitle("Permissions");
				break;
			case "/settings/chrome-extension":
				setTitle("Chrome Extension");
				break;

			case "/settings/email-parser":
				setTitle("Email Parser");
				break;

			case "/settings/notifications":
				setTitle("Notifications");
				break;
			case "/settings/webhooks":
				setTitle("Webhooks");
				break;

			default:
				break;
		}
	}, [location]);

	return (
		<div>
			<SettingsSideNav />
			<SettingsContent>
				<HeaderContainer>
					<div>
						<Back
							onClick={() => {
								history.push(`/inbox/`);
							}}
							title={"Back to " + theme.appName}
						/>
					</div>
					<PageHeader>{title}</PageHeader>
				</HeaderContainer>
				<Switch>
					<Route path="/settings/profile">
						<Profile />
					</Route>
					<Route path="/settings/notifications">
						<NotificationSettings />
					</Route>
					<AdminRoute path="/settings/opening-hours">
						<OpeningHours />
					</AdminRoute>
					<AdminRoute path="/settings/tags">
						<TagsSettings />
					</AdminRoute>
					<AdminRoute path="/settings/subscribers">
						<Unsubscribe />
					</AdminRoute>
					<AdminRoute path="/settings/users">
						<UserManagement rootStore={rootStore} />
					</AdminRoute>
					<AdminRoute path="/settings/teams">
						<TeamManagement rootStore={rootStore} />
					</AdminRoute>
					<AdminRoute path="/settings/automation">
						<AutomationSettings rootStore={rootStore} />
						<InboxesSettings rootStore={rootStore} />
					</AdminRoute>
					<AdminRoute path="/settings/permissions">
						<Permissions />
					</AdminRoute>
					<AdminRoute path="/settings/chrome-extension">
						<ChromeExtensionSettings />
					</AdminRoute>
					<AdminRoute path="/settings/email-parser">
						<PortalResponderSettings />
					</AdminRoute>
					<AdminRoute path="/settings/webhooks">
						<WebhooksSettings />
					</AdminRoute>
				</Switch>
			</SettingsContent>
		</div>
	);
};

export default Settings;
