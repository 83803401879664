import { gql } from "@apollo/client";

export const GET_CONTACTS_DATA = gql`
	query getContactsData($start: date, $end: date, $inboxes: [String!]) {
		reporting_ContactsCreatedByInbox(
			where: {
				Date: { _gte: $start, _lte: $end }
				Inbox: { _in: $inboxes }
			}
			order_by: { Date: asc }
		) {
			Date
			Count
		}
	}
`;

export const GET_TEMPLATES_SENT_BY_DATE_DATA = gql`
	query getTemplatesSentByDate(
		$start: date
		$end: date
		$inboxes: [String!]
	) {
		reporting_TemplatesSentByInbox(
			where: {
				Date: { _gte: $start, _lte: $end }
				Inbox: { _in: $inboxes }
			}
			order_by: { Date: asc }
		) {
			Date
			Count
		}
	}
`;

export const GET_INBOUND_MESSAGE_DATA = gql`
	query getInboundMessages($start: date, $end: date, $inboxes: [String!]) {
		reporting_MessagesInboundByInbox(
			where: {
				Date: { _gte: $start, _lte: $end }
				Inbox: { _in: $inboxes }
			}
			order_by: { Date: asc }
		) {
			Date
			Count
		}
	}
`;

export const GET_OUTBOUND_MESSAGE_DATA = gql`
	query getInboundMessages($start: date, $end: date, $inboxes: [String!]) {
		reporting_MessagesOutboundByInbox(
			where: {
				Date: { _gte: $start, _lte: $end }
				Inbox: { _in: $inboxes }
			}
			order_by: { Date: asc }
		) {
			Date
			Count
		}
	}
`;

export const GET_CONVERSATIONS_DATA = gql`
	query getConversationsData($start: date, $end: date, $inboxes: [String!]) {
		reporting_ConversationsByInbox(
			where: {
				Date: { _gte: $start, _lte: $end }
				Inbox: { _in: $inboxes }
			}
			order_by: { Date: asc }
		) {
			Date
			Count
		}
	}
`;

export const GET_BOT_RESPONSES = gql`
	query getContactsData($start: date, $end: date, $inboxes: [String!]) {
		reporting_BotResponsesByInbox(
			where: {
				Date: { _gte: $start, _lte: $end }
				Inbox: { _in: $inboxes }
			}
			order_by: { Date: asc }
		) {
			Date
			Count
			Inbox
			BotResponse
		}
	}
`;

export const GET_USER_INBOXES = gql`
	query getUserInboxes($userId: Int!) {
		msgbox_UserInbox(
			where: {
				UserId: { _eq: $userId }
				Inbox: { Name: { _is_null: false } }
			}
		) {
			Inbox {
				Name
				InboxId
			}
		}
	}
`;

export const GET_RESELLER_NAME = gql`
	query getResellerName($orgId: Int!) {
		msgbox_Organisation(where: { OrganisationId: { _eq: $orgId } }) {
			Reseller {
				Brand
				Name
			}
		}
	}
`;
