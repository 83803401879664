import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_TEMPLATES_STATUS_BARCHART_DATA } from "./query";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import { TemplatesChartContainer } from "../styles";
import { useTheme } from "styled-components";
import MsgBoxSelect from "../../../Components/MsgBoxSelect/msg-box-select.component";

const TemplatesIndividualStatus = ({ start, end, inboxes }) => {
	const [barchartData, setBarchartData] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [templateOptions, setTemplateOptions] = useState([]);
	const theme = useTheme();

	const { error, loading } = useQuery(GET_TEMPLATES_STATUS_BARCHART_DATA, {
		variables: {
			start,
			end,
			inboxes,
		},
		fetchPolicy: "no-cache",
		onCompleted: ({ reporting_TemplatesSentByInbox }) => {
			const templateMap = new Map();

			reporting_TemplatesSentByInbox.forEach((template) => {
				const existingData = templateMap.get(template.Name) || {
					templateName: template.Name,
					Sent: 0,
					Delivered: 0,
					Failed: 0,
					Read: 0,
					Replied: 0,
				};

				existingData.Sent += template.SentCount;
				existingData.Delivered += template.DeliveredCount;
				existingData.Failed += template.FailedCount;
				existingData.Read += template.ReadCount;
				existingData.Replied += template.RepliedCount;

				templateMap.set(template.Name, existingData);
			});

			const output = Array.from(templateMap.values()).map((template) => ({
				...template,
				Sent: template.Sent === 0 ? 0.1 : template.Sent,
				Delivered: template.Delivered === 0 ? 0.1 : template.Delivered,
				Failed: template.Failed === 0 ? 0.1 : template.Failed,
				Read: template.Read === 0 ? 0.1 : template.Read,
				Replied: template.Replied === 0 ? 0.1 : template.Replied,
			}));

			setBarchartData(output);

			const options = output.map((template) => ({
				value: template.templateName,
				label: template.templateName,
			}));

			options.sort((a, b) => a.label.localeCompare(b.label));
			setTemplateOptions(options);

			if (options.length > 0) {
				setSelectedTemplate(options[0]);
			}
		},
	});

	if (error) return null;
	if (loading) return null;
	if (barchartData.length === 0) return null;

	const displayData = barchartData.filter(
		(template) => template.templateName === selectedTemplate?.value
	);

	return (
		<TemplatesChartContainer>
			<div
				style={{
					marginBottom: "20px",
					width: "100%",
					flexShrink: 0,
				}}
			>
				<MsgBoxSelect
					placeholder="Select Template"
					label="Select Template"
					options={templateOptions}
					value={selectedTemplate}
					onChange={setSelectedTemplate}
				/>
			</div>
			<div
				style={{
					width: "100%",
					flex: 1,
					minHeight: 0,
				}}
			>
				<ResponsiveContainer width="100%" height="100%">
					<BarChart data={displayData}>
						<CartesianGrid />
						<XAxis
							type="category"
							dataKey="templateName"
							height={80}
							interval={0}
						/>
						<YAxis
							domain={[0, (dataMax) => Math.max(dataMax, 5.0)]}
						/>
						<Tooltip
							formatter={(value) => (value === 0.1 ? "0" : value)}
						/>
						<Legend verticalAlign="top" height={36} />
						<Bar
							dataKey="Sent"
							fill={theme.colours.primary}
							name="Sent"
							minPointLength={1}
						/>
						<Bar
							dataKey="Delivered"
							fill="#2F855A"
							name="Delivered"
							minPointLength={1}
						/>
						<Bar
							dataKey="Failed"
							fill="#C53030"
							name="Failed"
							minPointLength={1}
						/>
						<Bar
							dataKey="Replied"
							fill="#DD6B20"
							name="Replied"
							minPointLength={1}
						/>
						<Bar
							dataKey="Read"
							fill="#6B46C1"
							name="Read"
							minPointLength={1}
						/>
					</BarChart>
				</ResponsiveContainer>
			</div>
		</TemplatesChartContainer>
	);
};

export default TemplatesIndividualStatus;
