import { useState, useRef, useEffect, useContext } from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import client from "../../Apollo";
import useOutsideAlerter from "../../hooks/detect-outside";
import { stringInsert } from "../../helpers/functions";
import {
	$selectedInbox,
	$selectedConversation,
	$selectedFolder,
	$isMessageSending,
} from "../../store";
import { UserContext } from "../../Providers/user-provider";
import { getAccessToken } from "../../helpers/functions";
import {
	GET_INBOX_NATURAL_CHAT,
	GET_CONTACT_ID,
} from "../SendDeferredMessage/query";
import RecordVoice from "../RecordVoice/record-voice.component";
import {
	Container,
	MessageIcons,
	EmojiPickerContainer,
} from "../SendDeferredMessage/styles";
import SelectFile from "./select-file/select-file";
import NewNaturalChatInput from "./new-natural-chat-input";
import {
	GET_TEMPLATE_MESSAGE_BY_NAME,
	SEND_TEMPLATE_MESSAGE,
	ADD_MESSAGE_TO_CONVERSATION_DEFERRED,
} from "./query";
import useUploadFile from "../../helpers/use-upload-file";
import {
	NATURAL_CHAT_TEMPLATE_MEDIA_NAME,
	NATURAL_CHAT_TEMPLATE_TEXT_NAME,
} from "./constants";
import SendTemplateMessage from "../SendTemplateMessage/send-template-message.component";
import { GET_FOLDER, MARK_CONVERSATION_AS_READ } from "../Layout/query";
import { CHECK_MIME_TYPE } from "../SendTemplateMessage/query";
import { useLazyQuery } from "@apollo/client";
import ConvertingModal from "../ConvertingModal/convertingModal";

const NewNaturalChat = ({ rootStore }) => {
	const disabledFields = {
		searchBy: true,
		contacts: true,
		tags: true,
		templateMessages: false,
		account: true,
		textMessages: true,
	};

	const user = useContext(UserContext);

	const [blockFileSelection, setBlockFileSelection] = useState(false);
	const [charCount, setCharCount] = useState(0);
	const [charLimit, setCharLimit] = useState(0);
	const [templateLength, setTemplateLength] = useState(0);
	const [sendTemplateMessageIsOpen, setTemplateMessageModalOpen] =
		useState(false);

	const [files, setFiles] = useState([]);
	const isMessageSending = useReactiveVar($isMessageSending);

	const { uploadAttachment, loading: uploadLoading } = useUploadFile();

	const [sendTemplate, { loadTemp, errTemp }] = useMutation(
		SEND_TEMPLATE_MESSAGE
	);

	const [addMessageMutation, { error, loading }] = useMutation(
		ADD_MESSAGE_TO_CONVERSATION_DEFERRED
	);

	const [mutationMakeConversationAsRead] = useMutation(
		MARK_CONVERSATION_AS_READ
	);

	const [message, setMessage] = useState("");
	const inputRef = useRef(null);
	const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
	const [aiVisible, setAiVisible] = useState(false);
	const wrapperRef = useRef(null);
	const outsideClick = useOutsideAlerter(wrapperRef);
	const [cursorPosition, setCursorPosition] = useState(null);
	const [fileUploadIsOpen, setFileUploadOpen] = useState(false);
	const selectedConversation = useReactiveVar($selectedConversation);
	const selectedFolder = useReactiveVar($selectedFolder);
	const selectedInbox = useReactiveVar($selectedInbox);
	const [accountId, setAccountId] = useState(null);
	const [contactId, setContactId] = useState(null);
	const [audioRecordIsOpen, setAudioRecordOpen] = useState(false);

	const [checkMimeType] = useLazyQuery(CHECK_MIME_TYPE);
	const TIMEOUT_DURATION = 1200000;
	const [converting, setConverting] = useState(false);
	const [converted, setIsConverted] = useState();

	useEffect(() => {
		if (user.orgId == 1 || user.orgId == 34) {
			setAiVisible(true);
		}
		document.addEventListener("keydown", closeEmojiPicker);
		return () => {
			document.removeEventListener("keydown", closeEmojiPicker);
		};
	}, []);

	useEffect(() => {
		setEmojiPickerVisible(false);
	}, [outsideClick]);

	useEffect(() => {
		setCharCount(0);
		setMessage("");
	}, [selectedConversation]);

	useEffect(() => {
		loadTemplateId();
		loadContactId();
	});

	async function checkAttachment(
		attachmentId,
		templateId,
		templateText,
		accountId,
		contactId,
		conversationId
	) {
		setConverting(true);
		const startTime = Date.now();
		async function retryQuery() {
			if (Date.now() - startTime >= TIMEOUT_DURATION) {
				setConverting(false);
				console.warn("Request timed out.");
				return Promise.resolve();
			}

			try {
				const data = await checkMimeType({
					variables: { attachmentId },
					fetchPolicy: "network-only",
				});
				if (data.data.msgbox_Attachment[0].MimeType === "video/mp4") {
					setIsConverted(true);
					setConverting(false);
					await sendTemplate({
						variables: {
							TemplateId: templateId,
							Message: templateText,
							account: accountId,
							contacts: {
								data: [{ ContactId: contactId }],
							},
						},
					});
					await addMessageMutation({
						variables: {
							bodyText: "",
							conversationId: conversationId,
							attachmentId: attachmentId,
						},
					});
					return Promise.resolve();
				} else {
					console.log("retrying.. ");
					await new Promise((resolve) =>
						setTimeout(() => resolve(), 1000)
					); // Wait for 1 second
					retryQuery();
				}
			} catch (e) {
				console.log(e);
				setIsConverted(true);
				setConverting(false);
				return Promise.resolve();
			}
		}
		retryQuery();
	}

	const loadContactId = async () => {
		const getContact = await client.query({
			query: GET_CONTACT_ID,
			variables: {
				conversationId: selectedConversation,
			},
		});
		const d = getContact.data.msgbox_Conversation;

		if (d.length > 0) {
			setContactId(d[0].ContactId);
		}
	};

	const markAsRead = async () => {
		let data = await client.query({
			query: GET_FOLDER,
			variables: { conversationId: selectedConversation },
		});
		if (data?.data?.msgbox_Conversation[0]?.FolderId) {
			mutationMakeConversationAsRead({
				variables: {
					conversationId: selectedConversation,
				},
			});
		}
	};

	const loadTemplateId = async () => {
		const getTemplate = await client.query({
			query: GET_INBOX_NATURAL_CHAT,
			variables: {
				inboxId: selectedInbox,
			},
		});
		const newApiAcct =
			getTemplate.data.msgbox_UserInbox[0].Inbox.APIAccount;
		setAccountId(newApiAcct.APIAccountId);
	};

	const closeEmojiPicker = (event) => {
		if (event.key === "Escape") {
			setEmojiPickerVisible(false);
		}
	};

	const sendAudio = async (attachmentId) => {
		if (!attachmentId) return;
		$isMessageSending(true);

		let templateName = NATURAL_CHAT_TEMPLATE_MEDIA_NAME;

		const getNaturalChatMediaTemplate = await client.query({
			query: GET_TEMPLATE_MESSAGE_BY_NAME,
			variables: {
				name: templateName,
				userInboxes: [selectedInbox],
			},
		});

		const template = getNaturalChatMediaTemplate.data.msgbox_Template[0];
		const templateText = template.TemplateText.replace(
			"{{1}}",
			"You have an attachment waiting"
		);

		await sendTemplate({
			variables: {
				TemplateId: template.TemplateId,
				Message: templateText,
				account: accountId,
				contacts: {
					data: [{ ContactId: contactId }],
				},
			},
		});
		await addMessageMutation({
			variables: {
				bodyText: "",
				conversationId: selectedConversation,
				attachmentId: attachmentId,
			},
		});
	};

	const sendNewNaturalChatTemplate = async (pastedFile, clearJar) => {
		if (
			!(
				message ||
				message === " " ||
				message === "  " ||
				!pastedFile ||
				!(files.length > 0)
			)
		) {
			return;
		}
		$isMessageSending(true);
		const formattedMesage = message.replace(/(?:\r\n|\r|\n)/g, ".");
		if (files && files.length > 0) {
			const file = files[0];
			const attachmentId = await uploadAttachment(file);
			let templateName = NATURAL_CHAT_TEMPLATE_MEDIA_NAME;
			const getNaturalChatMediaTemplate = await client.query({
				query: GET_TEMPLATE_MESSAGE_BY_NAME,
				variables: {
					name: templateName,
					userInboxes: [selectedInbox],
				},
			});
			const template =
				getNaturalChatMediaTemplate.data.msgbox_Template[0];
			const templateText = template.TemplateText.replace(
				"{{1}}",
				formattedMesage
					? formattedMesage
					: "You have an attachment waiting"
			);

			if (file.type.includes("video")) {
				//checks *and* sends
				await checkAttachment(
					attachmentId,
					template.TemplateId,
					templateText,
					accountId,
					contactId,
					selectedConversation
				);
				setFiles([]);
			} else {
				await sendTemplate({
					variables: {
						TemplateId: template.TemplateId,
						Message: templateText,
						account: accountId,
						contacts: {
							data: [{ ContactId: contactId }],
						},
					},
				});
				await addMessageMutation({
					variables: {
						bodyText: "",
						conversationId: selectedConversation,
						attachmentId: attachmentId,
					},
				});
				setFiles([]);
			}
		} else if (pastedFile && pastedFile.file) {
			const attachmentId = await uploadAttachment(pastedFile.file);
			let templateName = NATURAL_CHAT_TEMPLATE_MEDIA_NAME;
			const getNaturalChatMediaTemplate = await client.query({
				query: GET_TEMPLATE_MESSAGE_BY_NAME,
				variables: {
					name: templateName,
					userInboxes: [selectedInbox],
				},
			});
			const template =
				getNaturalChatMediaTemplate.data.msgbox_Template[0];
			const templateText = template.TemplateText.replace(
				"{{1}}",
				formattedMesage
					? formattedMesage
					: "You have an attachment waiting"
			);

			if (pastedFile.file.type.includes("video")) {
				//checks *and* sends
				await checkAttachment(
					attachmentId,
					template.TemplateId,
					templateText,
					accountId,
					contactId,
					selectedConversation
				);
				clearJar();
			} else {
				await sendTemplate({
					variables: {
						TemplateId: template.TemplateId,
						Message: templateText,
						account: accountId,
						contacts: {
							data: [{ ContactId: contactId }],
						},
					},
				});
				await addMessageMutation({
					variables: {
						bodyText: "",
						conversationId: selectedConversation,
						attachmentId: attachmentId,
					},
				});
			}
			clearJar();
		} else {
			const getNaturalChatTextTemplate = await client.query({
				query: GET_TEMPLATE_MESSAGE_BY_NAME,
				variables: {
					name: NATURAL_CHAT_TEMPLATE_TEXT_NAME,
					userInboxes: [selectedInbox],
				},
			});
			const template = getNaturalChatTextTemplate.data.msgbox_Template[0];
			const templateText = template.TemplateText.replace(
				"{{1}}",
				formattedMesage
			);
			await sendTemplate({
				variables: {
					attachmentId: null,
					TemplateId: template.TemplateId,
					Message: templateText,
					account: accountId,
					contacts: {
						data: [{ ContactId: contactId }],
					},
				},
			});
		}
		await markAsRead();
		setMessage("");
		setCharCount(0);
	};

	// useEffect(() => {
	// 	const fetchTemplateLength = async () => {
	// 		const { data } = await client.query({
	// 			query: GET_TEMPLATE_MESSAGE_BY_NAME,
	// 			variables: {
	// 				name: NATURAL_CHAT_TEMPLATE_MEDIA_NAME,
	// 				userInboxes: [selectedInbox],
	// 			},
	// 		});

	// 		const template = data?.msgbox_Template?.[0];
	// 		if (template) {
	// 			setTemplateLength(template.TemplateText.length);
	// 		}
	// 	};

	// 	fetchTemplateLength();
	// }, [selectedInbox]);

	const handleMessageChange = (event) => {
		const maxAllowed = 1024;

		setCharCount(event.target.value.length);
		setCharLimit(maxAllowed);

		if (event.target.value.length >= maxAllowed) return;

		setCursorPosition(event.target.selectionStart);
		setMessage(event.target.value);
	};

	const handleInputClick = (event) => {
		setCursorPosition(event.target.selectionStart);
	};

	const handleEmojiChange = (emoji) => {
		const newMessage = stringInsert(message, cursorPosition, emoji.native);
		setMessage(newMessage);
		setCursorPosition(cursorPosition + 2);
		setEmojiPickerVisible(false);
	};

	async function generateAIResponse() {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}conversation/${selectedConversation}/suggest`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			}
		);
		const json = await response.json();
		setMessage(json?.response?.suggestedResponse);
	}

	if (!selectedConversation || selectedConversation === -1) return null;
	return (
		<Container>
			<ConvertingModal isOpen={converting} />
			<div className="send-message-actions">
				<MessageIcons
					disabled={(files && files.length > 0) || blockFileSelection}
					onClick={() => {
						setFileUploadOpen(true);
					}}
					className="fas fa-paperclip"
				></MessageIcons>
				<MessageIcons
					active={emojiPickerVisible}
					onClick={() => {
						setEmojiPickerVisible(!emojiPickerVisible);
					}}
					className={
						emojiPickerVisible
							? "far fa-laugh emoji-picker"
							: "far fa-smile"
					}
				></MessageIcons>

				<MessageIcons
					onClick={() => {
						setAudioRecordOpen(true);
					}}
					className="fas fa-microphone"
					disabled={
						user.newNaturalChatConfig[selectedInbox]
							.newNaturalChatMedia === false
					}
				></MessageIcons>

				<MessageIcons
					onClick={() => {
						setTemplateMessageModalOpen(true);
					}}
					className="far fa-edit"
				></MessageIcons>
				{aiVisible && (
					<MessageIcons
						onClick={() => {
							generateAIResponse();
						}}
						className="fas fa-magic"
					></MessageIcons>
				)}
				{files.length > 0 &&
					files.map((image) => (
						<div key={image.name} className="pasted-container-1">
							<div>
								{image.type === "application/pdf" ? (
									<i className="fas fa-file-pdf"></i>
								) : (
									<i className="fas fa-file"></i>
								)}
								{image.name}
							</div>

							<button
								disabled={isMessageSending}
								onClick={() => setFiles([])}
							>
								<i className="fas fa-trash"></i>
							</button>
						</div>
					))}
				{emojiPickerVisible && (
					<EmojiPickerContainer ref={wrapperRef}>
						<Picker
							title="Pick your emoji…"
							emoji="point_up"
							onSelect={handleEmojiChange}
						/>
					</EmojiPickerContainer>
				)}
			</div>
			<NewNaturalChatInput
				message={message}
				setMessage={setMessage}
				addMessage={sendNewNaturalChatTemplate}
				inputRef={inputRef}
				handleMessageChange={handleMessageChange}
				handleInputClick={handleInputClick}
				selectedConversation={selectedConversation}
				setBlockFileSelection={setBlockFileSelection}
				blockPastingFiles={files && files.length > 0}
				charCount={charCount}
				charLimit={charLimit}
			/>
			<SelectFile
				cancel={() => {
					setFileUploadOpen(false);
				}}
				isOpen={fileUploadIsOpen}
				onAccept={(file, caption) => {
					setFiles([file]);
					if (caption) {
						setMessage((m) => m.concat(caption));
					}
				}}
			/>
			<RecordVoice
				cancel={() => {
					setAudioRecordOpen(false);
				}}
				isOpen={audioRecordIsOpen}
				send={sendAudio}
			/>
			<SendTemplateMessage
				rootStore={rootStore}
				isOpen={sendTemplateMessageIsOpen}
				close={() => {
					setTemplateMessageModalOpen(false);
				}}
				disabledFields={disabledFields}
				account={accountId}
				contacts={[{ value: contactId, label: "" }]}
			/>
		</Container>
	);
};

export default NewNaturalChat;
