import { gql } from "@apollo/client";

export const GET_CONVERSATION = gql`
	query getConversation($convoId: Int!) {
		msgbox_Conversation(where: { ConversationId: { _eq: $convoId } }) {
			AssignedTo
			AssignedToDepartment
			ContactId
			ConversationUUID
			ConversationId
			CreatedOn
			Contact {
				MobileNumber
				ContactId
				Name
			}
			Department {
				Name
			}
			User {
				FirstName
				LastName
				UserId
			}
			Messages(order_by: { CreatedOn: desc }) {
				BodyText
				ContactCard
				CreatedOn
				DateTime
				MessageId
				SenderId
				User {
					FirstName
					LastName
					UserId
					UserDepartments {
						DepartmentId
						UserId
					}
				}
			}
		}
	}
`;
