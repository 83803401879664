import { useRef, useEffect, useState, useContext } from "react";
import { useQuery } from "@apollo/client";
import { useVirtualizer } from "@tanstack/react-virtual";
import { GET_MESSAGES_IN_CONVERSATION } from "./query";
import { getUserId } from "../../helpers/functions";
import Message from "../../Components/Message/message.component";
import { Container } from "./styles";
import { useReactiveVar } from "@apollo/client";
import {
	$isMessageSending,
	$reuseableDialog,
	$selectedConversation,
} from "../../store";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
import { LoadingSpinnerContainer } from "./styles";
import useWebSocket from "react-use-websocket";
import { UserContext } from "../../Providers/user-provider";
import PendingMessage from "../Message/pending-message";
import { useToasts } from "react-toast-notifications";

const MessageList = ({ loadMessageConditions }) => {
	const { addToast } = useToasts();

	const isMessageSending = useReactiveVar($isMessageSending);
	const [filteredData, setFilteredData] = useState([]);
	const selectedConversation = useReactiveVar($selectedConversation);
	const user_id = getUserId();
	const userContext = useContext(UserContext);

	const { loading, error, data, refetch } = useQuery(
		GET_MESSAGES_IN_CONVERSATION,
		{
			variables: {
				conversationId: selectedConversation
					? selectedConversation
					: -1,
			},
			fetchPolicy: "network-only",
		}
	);
	const { lastMessage } = useWebSocket(
		`${process.env.REACT_APP_WSAPI_URL}?orgid=${
			userContext.orgId
		}&token=${localStorage.getItem("access_token")}`,
		{
			reconnectAttempts: 100,
			reconnectInterval: (attemptNumber) =>
				Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
			share: true,
			shouldReconnect: (closeEvent) => true,
			onError: handleConnectionFailure,
		}
	);

	function handleConnectionFailure() {
		$reuseableDialog({
			visible: true,
			title: "Lost connection to the server",
			body: "You must refresh the page or no new messages will show",
			onPress: () => window.location.reload(),
			button: "Refresh",
		});
	}

	useEffect(() => {
		if (lastMessage && lastMessage.data) {
			const incomingData = JSON.parse(lastMessage.data);
			if (
				incomingData.conversationId == selectedConversation &&
				incomingData.type == "conversation_new_message"
			) {
				refetch();
				loadMessageConditions();
				$isMessageSending(false);
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (data && selectedConversation != null && data.msgbox_Message) {
			const myFilteredData = data.msgbox_Message.filter(
				(message) => message.ConversationId === selectedConversation
			);
			if (myFilteredData && myFilteredData.length > 0) {
				setFilteredData(myFilteredData);
			}
		}
	}, [data]);

	const chatWindowRef = useRef(null);
	const count = filteredData ? filteredData.length : 0;
	const virtualizer = useVirtualizer({
		count,
		getScrollElement: () => chatWindowRef.current,
		estimateSize: () => 90,
	});

	useEffect(() => {
		if (!loading && !error && chatWindowRef.current) {
			virtualizer.scrollToOffset(chatWindowRef.current.scrollHeight, {
				align: "end",
			});
			setTimeout(() => {
				virtualizer.scrollToOffset(chatWindowRef.current.scrollHeight, {
					align: "end",
				});
			}, 500);
		}
	}, [filteredData]);

	if (error) {
		return <p>Something went wrong...</p>;
	}
	const items = virtualizer.getVirtualItems();
	return (
		<Container ref={chatWindowRef}>
			{loading === true ? (
				<LoadingSpinnerContainer>
					<LoadingSpinner />
				</LoadingSpinnerContainer>
			) : !selectedConversation || selectedConversation == -1 ? (
				<LoadingSpinnerContainer>
					<h3>Select a conversation</h3>
				</LoadingSpinnerContainer>
			) : (
				<>
					<div
						style={{
							height: virtualizer.getTotalSize(),
							width: "100%",
							position: "relative",
						}}
					>
						<div
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								transform: `translateY(${
									items && items.length > 0
										? isMessageSending
											? items[0].start - 74
											: items[0].start
										: 0
								}px)`,
							}}
						>
							{items.map((virtualRow) => {
								const message = filteredData[virtualRow.index];
								return (
									<Message
										id="message"
										ref={virtualizer.measureElement}
										messageId={message.MessageId}
										key={message.MessageId}
										rowKey={virtualRow.key}
										dataIndex={virtualRow.index}
										message={message.BodyText}
										dateTime={message.DateTime}
										image={message.User?.signedurl?.url}
										isIncoming={
											message.User?.UserId == user_id
										}
										OriginatingMessage={
											message.OriginatingMessage
										}
										user={message.User}
										isRead={message.IsRead}
										isSent={message.IsSent}
										isDelivered={message.IsDelivered}
										isFailed={message.IsFailed}
										sendFailureReason={
											message.SendFailureReason
										}
										isPending={message.DeferredDelivery}
										contact={
											message.Conversation?.Contact
												?.Name &&
											message.Conversation?.Contact.Name
										}
										attachment={message.Attachment}
										lat={message.Latitude}
										long={message.Longitude}
										contactCard={message.ContactCard}
										senderId={message.SenderId}
										originatingAttachmentId={
											message.OriginatingAttachmentId
										}
									/>
								);
							})}
							{isMessageSending &&
							filteredData &&
							filteredData.length > 0 ? (
								<PendingMessage
									user={
										filteredData[filteredData.length - 1]
											.User
									}
									image={
										filteredData[filteredData.length - 1]
											.User?.signedurl?.url
									}
								/>
							) : null}
						</div>
					</div>
				</>
			)}
		</Container>
	);
};

export default MessageList;
