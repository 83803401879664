import { useState, useRef, useEffect, useContext } from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import { ADD_MESSAGE_TO_CONVERSATION, GET_API_AND_CONTACT } from "./query";
import { Container, MessageIcons, EmojiPickerContainer } from "./styles";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import useOutsideAlerter from "../../hooks/detect-outside";
import { stringInsert } from "../../helpers/functions";
import UploadFile from "../UploadFile/upload-file.component";
import RecordVoice from "../RecordVoice/record-voice.component";
import { getAccessToken } from "../../helpers/functions";
import {
	$isMessageSending,
	$selectedConversation,
	$selectedInbox,
} from "../../store";
import ReactGA from "react-ga4";
import InputMessageWithPastingFiles from "../InputMessageWithPastingFiles/input-message-with-pasting-files";
import SendTemplateMessage from "../SendTemplateMessage/send-template-message.component";
import client from "../../Apollo";
import { GET_FOLDER, MARK_CONVERSATION_AS_READ } from "../Layout/query";
import { UserContext } from "../../Providers/user-provider";
import { GET_TEMPLATE_MESSAGE_BY_NAME } from "../new-natural-chat/query";
import { NATURAL_CHAT_TEMPLATE_MEDIA_NAME } from "../new-natural-chat/constants";

const SendMessage = ({ rootStore }) => {
	const disabledFields = {
		searchBy: true,
		contacts: true,
		tags: true,
		templateMessages: false,
		account: true,
		textMessages: true,
	};

	const [addMessageMutation, { error, loading }] = useMutation(
		ADD_MESSAGE_TO_CONVERSATION
	);
	const [mutationMakeConversationAsRead] = useMutation(
		MARK_CONVERSATION_AS_READ
	);
	const [message, setMessage] = useState("");
	const inputRef = useRef(null);
	const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
	const [aiVisible, setAiVisible] = useState(false);
	const wrapperRef = useRef(null);
	const outsideClick = useOutsideAlerter(wrapperRef);
	const [cursorPosition, setCursorPosition] = useState(null);
	const [fileUploadIsOpen, setFileUploadOpen] = useState(false);
	const [audioRecordIsOpen, setAudioRecordOpen] = useState(false);
	const selectedConversation = useReactiveVar($selectedConversation);
	const selectedInbox = useReactiveVar($selectedInbox);
	const [charLimit, setCharLimit] = useState(0);
	const [charCount, setCharCount] = useState(0);
	const [templateLength, setTemplateLength] = useState(0);

	const [sendTemplateMessageIsOpen, setTemplateMessageModalOpen] =
		useState(false);
	const [accountId, setAccountId] = useState(null);
	const [contactId, setContactId] = useState(null);
	const userContext = useContext(UserContext);
	const inboxStore = rootStore.getInboxStore();

	useEffect(() => {
		setCharCount(0);
		//console.log("userContext:", userContext);
		if (inboxStore.isAIReplyAllowed(selectedInbox)) {
			setAiVisible(true);
		}
		document.addEventListener("keydown", closeEmojiPicker);
		return () => {
			document.removeEventListener("keydown", closeEmojiPicker);
		};
	}, []);

	useEffect(() => {
		setEmojiPickerVisible(false);
	}, [outsideClick]);

	useEffect(() => {
		// Set message as "" so that when a user changes the conversation the message gets removed.
		// Drafts??
		setCharCount(0);
		getContactAndApi();
		setMessage("");
	}, [selectedConversation]);

	const closeEmojiPicker = (event) => {
		if (event.key === "Escape") {
			setEmojiPickerVisible(false);
		}
	};

	async function getContactAndApi() {
		let temp = await client.query({
			query: GET_API_AND_CONTACT,
			variables: {
				inboxId: selectedInbox,
				conversationId: selectedConversation,
			},
		});
		if (!temp.data) return;
		if (temp.data.msgbox_Conversation && temp.data.msgbox_Conversation[0]) {
			setContactId(temp.data.msgbox_Conversation[0].ContactId);
		}
		if (temp.data.msgbox_Inbox && temp.data.msgbox_Inbox[0]) {
			setAccountId(temp.data.msgbox_Inbox[0].APIAccountId);
		}
	}

	const markAsRead = async () => {
		let data = await client.query({
			query: GET_FOLDER,
			variables: { conversationId: selectedConversation },
		});
		if (data?.data?.msgbox_Conversation[0]?.FolderId) {
			mutationMakeConversationAsRead({
				variables: {
					conversationId: selectedConversation,
				},
			});
		}
	};

	const addMessage = (e) => {
		// add the char limit here ?
		const trimmedMessage = message.trim();
		if (!trimmedMessage) return;

		if (message && selectedConversation) {
			addMessageMutation({
				variables: {
					bodyText: trimmedMessage,
					conversationId: selectedConversation,
				},
			});
			markAsRead();
			ReactGA.event({
				category: "Messages",
				action: "SentMessage",
			});
			setCharCount(0);
			setMessage("");
			$isMessageSending(true);
		}
	};

	const sendAttachment = async (attachmentId, caption) => {
		await addMessageMutation({
			variables: {
				bodyText: caption,
				conversationId: selectedConversation,
				attachmentId,
			},
		});
		if (caption && caption.length > 0) {
			setMessage("");
		}
	};

	useEffect(() => {
		const fetchTemplateLength = async () => {
			const { data } = await client.query({
				query: GET_TEMPLATE_MESSAGE_BY_NAME,
				variables: {
					name: NATURAL_CHAT_TEMPLATE_MEDIA_NAME,
					userInboxes: [selectedInbox],
				},
			});

			const template = data?.msgbox_Template?.[0];
			if (template) {
				setTemplateLength(template.TemplateText.length);
			}
		};

		fetchTemplateLength();
	}, [selectedInbox]);

	const handleMessageChange = (event) => {
		const maxAllowed = 1024 - templateLength;

		setCharCount(event.target.value.length);
		setCharLimit(maxAllowed);

		if (event.target.value.length >= maxAllowed) return;

		setCursorPosition(event.target.selectionStart);
		setMessage(event.target.value);
	};

	const handleInputClick = (event) => {
		setCursorPosition(event.target.selectionStart);
	};

	const handleEmojiChange = (emoji) => {
		const newMessage = stringInsert(message, cursorPosition, emoji.native);
		setMessage(newMessage);
		setCursorPosition(cursorPosition + 2);
		setEmojiPickerVisible(false);
	};

	async function generateAIResponse() {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}conversation/${selectedConversation}/suggest`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${await getAccessToken()}`,
				},
			}
		);
		const json = await response.json();
		setMessage(json?.response?.suggestedResponse);
	}

	if (!selectedConversation || selectedConversation === -1) return null;

	return (
		<Container>
			<div className="send-message-actions">
				<MessageIcons
					onClick={() => {
						setFileUploadOpen(true);
					}}
					className="fas fa-paperclip"
				></MessageIcons>
				<MessageIcons
					active={emojiPickerVisible}
					onClick={() => {
						setEmojiPickerVisible(!emojiPickerVisible);
					}}
					className={
						emojiPickerVisible
							? "far fa-laugh emoji-picker"
							: "far fa-smile"
					}
				></MessageIcons>
				<MessageIcons
					onClick={() => {
						setAudioRecordOpen(true);
					}}
					className="fas fa-microphone"
				></MessageIcons>
				<MessageIcons
					onClick={() => {
						setTemplateMessageModalOpen(true);
					}}
					className="far fa-edit"
				></MessageIcons>
				{aiVisible && (
					<MessageIcons
						onClick={() => {
							generateAIResponse();
						}}
						className="fas fa-magic"
					></MessageIcons>
				)}
				{emojiPickerVisible && (
					<EmojiPickerContainer ref={wrapperRef}>
						<Picker
							title="Pick your emoji…"
							emoji="point_up"
							onSelect={handleEmojiChange}
						/>
					</EmojiPickerContainer>
				)}
			</div>
			<InputMessageWithPastingFiles
				sendAttachment={sendAttachment}
				message={message}
				setMessage={setMessage}
				addMessage={addMessage}
				inputRef={inputRef}
				handleMessageChange={handleMessageChange}
				setCharLimit={setCharLimit}
				setCharCount={setCharCount}
				charCount={charCount}
				charLimit={charLimit}
				handleInputClick={handleInputClick}
				selectedConversation={selectedConversation}
				isNaturalChatWindow={false}
			/>
			<UploadFile
				cancel={() => {
					setFileUploadOpen(false);
				}}
				isOpen={fileUploadIsOpen}
				send={sendAttachment}
			/>
			<RecordVoice
				cancel={() => {
					setAudioRecordOpen(false);
				}}
				isOpen={audioRecordIsOpen}
				send={sendAttachment}
			/>
			<SendTemplateMessage
				rootStore={rootStore}
				isOpen={sendTemplateMessageIsOpen}
				close={() => {
					setTemplateMessageModalOpen(false);
				}}
				disabledFields={disabledFields}
				account={accountId}
				contacts={[{ value: contactId, label: "" }]}
			/>
		</Container>
	);
};

export default SendMessage;
