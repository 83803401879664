import { useEffect, useState, useRef, useContext, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { useVirtualizer } from "@tanstack/react-virtual";
import MessagePreview from "../MessagePreview/message-preview.component";
import { GETALL_CONVERSATIONS, GET_CONVERSATION } from "./query";
import { Container, SpinnerContainer } from "./styles";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
import {
	$selectedFolder,
	$selectedConversation,
	$infoPanelToggleIsHidden,
	$conversationListSearchTerm,
	$conversationUserFilter,
	$conversationTagFilter,
	$conversationDepartmentFilter,
	$conversationContactFilter,
	$unassignedConversationFilter,
	$assignedToMeConversationFilter,
	$unreadConversationFilter,
	$assignedToTeamsConversationFilter,
	$isMessageSending,
	$conversationInboxFilter,
	$conversationFolderFilter,
	$selectedInbox,
	$userFolders,
	$reuseableDialog,
	$downloadChatButtonIsHidden,
} from "../../store";
import { useReactiveVar } from "@apollo/client";
import ReactGA from "react-ga4";
import useWebSocket from "react-use-websocket";
import { UserContext } from "../../Providers/user-provider";
import client from "../../Apollo";
import { useToasts } from "react-toast-notifications";

const ConversationList = () => {
	const { addToast } = useToasts();

	const [allData, setAllData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	const selectedInboxes = useReactiveVar($conversationInboxFilter);
	const currentConv = useReactiveVar($selectedConversation);
	const assignedToMeConversationFilter = useReactiveVar(
		$assignedToMeConversationFilter
	);
	const userFolders = useReactiveVar($userFolders);
	const unassignedConversationFilter = useReactiveVar(
		$unassignedConversationFilter
	);
	const unreadConversationFilter = useReactiveVar($unreadConversationFilter);

	const conversationFilterSearchTerm = useReactiveVar(
		$conversationListSearchTerm
	);
	const conversationUserFilter = useReactiveVar($conversationUserFilter);
	const conversationTagFilter = useReactiveVar($conversationTagFilter);
	const conversationDepartmentFilter = useReactiveVar(
		$conversationDepartmentFilter
	);
	const conversationContactFilter = useReactiveVar(
		$conversationContactFilter
	);
	const conversationFolderFilter = useReactiveVar($conversationFolderFilter);
	const assignedToTeamsConversationFilter = useReactiveVar(
		$assignedToTeamsConversationFilter
	);
	const containerRef = useRef(null);
	const [height, setHeight] = useState(25);
	const itemMeasures = useRef(new Map());

	const userContext = useContext(UserContext);

	const virtualizer = useVirtualizer({
		count: filteredData.length || 0,
		getScrollElement: () => containerRef.current,
		estimateSize: useCallback(
			(index) => itemMeasures.current.get(index) || 90,
			[]
		),
		overscan: 5,
	});

	useEffect(() => {
		virtualizer.measure();
	}, [filteredData, virtualizer]);

	const measureItem = useCallback(
		(index, size) => {
			itemMeasures.current.set(index, size);
			virtualizer.measure();
		},
		[virtualizer]
	);

	useEffect(() => {
		filterConversations(allData);
	}, [
		allData,
		assignedToMeConversationFilter,
		unassignedConversationFilter,
		unreadConversationFilter,
		assignedToTeamsConversationFilter,
		conversationFolderFilter,
	]);

	let foldersWithSameName =
		userFolders[conversationFolderFilter]?.map((item) => item.FolderId) ||
		[];

	const { loading, error, data, refetch } = useQuery(GETALL_CONVERSATIONS, {
		fetchPolicy: "no-cache",
		variables: {
			folderId: foldersWithSameName ? foldersWithSameName : -1,
			searchTerm: conversationFilterSearchTerm,
			userIds: conversationUserFilter,
			tagIds: conversationTagFilter,
			departmentIds: conversationDepartmentFilter,
			contactIds: conversationContactFilter,
		},
	});

	const { lastMessage } = useWebSocket(
		`${process.env.REACT_APP_WSAPI_URL}?orgid=${
			userContext.orgId
		}&token=${localStorage.getItem("access_token")}`,
		{
			reconnectAttempts: 100,
			reconnectInterval: (attemptNumber) =>
				Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
			share: true,
			shouldReconnect: (closeEvent) => true,
			onError: handleConnectionFailure,
		}
	);

	function handleConnectionFailure() {
		$reuseableDialog({
			visible: true,
			title: "Lost connection to the server",
			body: "You must refresh the page or no new messages will show",
			onPress: () => window.location.reload(),
			button: "Refresh",
		});
	}

	useEffect(() => {
		if (lastMessage && lastMessage.data) {
			const incomingData = JSON.parse(lastMessage.data);
			if (
				allData &&
				incomingData.type == "conversation_update" &&
				foldersWithSameName.includes(incomingData.folderId)
			) {
				// change on conversation in folder
				let inbox = userFolders[conversationFolderFilter].find(
					(item) => item.FolderId == incomingData.folderId
				);
				let item = { ...incomingData.item };
				item.Folder = { InboxId: inbox.inbox };
				item.defaultLastMesaage = incomingData.item;
				addOrUpdateItem(item);
			} else if (
				allData &&
				incomingData.type == "conversation_update" &&
				foldersWithSameName.includes(incomingData.oldFolderId)
			) {
				// conversation left folder
				removeConversation(incomingData.conversationId);
			} else if (allData && incomingData.type == "conversation_removed") {
				// conversation has been assigned elsewhere and i cant see it
				removeConversation(incomingData.conversationId);
			} else if (
				allData &&
				incomingData.type == "conversation_new_message" &&
				foldersWithSameName.includes(incomingData.folderId)
			) {
				// new message
				let item = allData.find(
					(conversation) =>
						conversation.ConversationId ==
						incomingData.conversationId
				);
				if (!item) return loadConversation(incomingData.conversationId);
				item.LastMessage = incomingData.item.LastMessage;
				item.UpdatedOn = incomingData.lastUpdated;
				item.IsRead = incomingData.item.IsRead;
				item.defaultLastMesaage = incomingData.item;
				addOrUpdateItem(item);
			} else if (allData && incomingData.type == "conversation_deleted") {
				// conversation was deleted
				removeConversation(incomingData.conversationId);
			}
		}
	}, [lastMessage]);

	async function loadConversation(conversationId) {
		let temp = await client.query({
			query: GET_CONVERSATION,
			variables: {
				conversationId: conversationId,
				folderId: foldersWithSameName ? foldersWithSameName : -1,
				searchTerm: conversationFilterSearchTerm,
				userIds: conversationUserFilter,
				tagIds: conversationTagFilter,
				departmentIds: conversationDepartmentFilter,
				contactIds: conversationContactFilter,
			},
		});
		if (
			temp &&
			temp.data.msgbox_Conversation &&
			temp.data.msgbox_Conversation[0]
		)
			addOrUpdateItem(temp.data.msgbox_Conversation[0]);
	}

	async function addOrUpdateItem(item) {
		let oldData = allData.filter(
			(conversation) =>
				conversation.ConversationId !== item.ConversationId
		);
		oldData.push(item);
		setAllData(oldData);
	}

	useEffect(() => {
		if (containerRef.current) {
			setHeight(containerRef.current?.clientHeight);
		}
	});

	useEffect(() => {
		if (data) {
			const currentConversation = data.msgbox_Conversation.filter(
				(conversation) => {
					return conversation.ConversationId === currentConv;
				}
			);
			if (!currentConversation[0]?.ConversationId) {
				$infoPanelToggleIsHidden(true);
			} else {
				$infoPanelToggleIsHidden(false);
			}
		}

		if (userContext.role === "orgadmin") {
			$downloadChatButtonIsHidden(null);
		} else {
			$downloadChatButtonIsHidden(true);
		}
	}, [currentConv, data, userContext.role]);

	useEffect(() => {
		if (data && data.msgbox_Conversation && !loading) {
			setAllData(data.msgbox_Conversation);
			if (data.msgbox_Conversation.length === 0) {
				$selectedConversation(null);
			}
		}
	}, [data, loading]);

	function filterConversations(convs) {
		if (convs) {
			const myFilteredData = convs
				.filter(function (conversation) {
					if (
						!assignedToMeConversationFilter &&
						!unassignedConversationFilter &&
						selectedInboxes.length == 0 &&
						(!assignedToTeamsConversationFilter ||
							assignedToTeamsConversationFilter.length == 0) &&
						!unreadConversationFilter
					) {
						return true;
					}

					if (selectedInboxes.length > 0) {
						let inSelectedInbox = selectedInboxes.find((item) =>
							item.folders.some(
								(folder) =>
									folder.FolderId === conversation.FolderId
							)
						);
						if (!inSelectedInbox) {
							if (conversation.AssignedToDepartment) {
								let isInSelectedTeam =
									assignedToTeamsConversationFilter.find(
										(e) =>
											e.department ==
											conversation.AssignedToDepartment
									);
								if (
									!isInSelectedTeam ||
									isInSelectedTeam?.inboxId
								)
									return false;
							} else {
								return false;
							}
						}
					}

					if (
						assignedToMeConversationFilter &&
						conversation.AssignedTo ==
							assignedToMeConversationFilter
					) {
						return true;
					}

					if (
						unassignedConversationFilter &&
						conversation.AssignedTo == null &&
						conversation.AssignedToDepartment == null
					) {
						return true;
					}
					if (unreadConversationFilter && !conversation.IsRead) {
						return true;
					}

					let converstatioinsInboxDepartments =
						selectedInboxes.length > 0
							? selectedInboxes.find((item) =>
									item.folders.some(
										(folder) =>
											folder.FolderId ===
											conversation.FolderId
									)
							  )
							: [];

					// if were assigned to a team in the currently assigned departments
					// or if this inbox doesnt have any departments that are currently assigned
					if (
						(selectedInboxes.length > 0 &&
							converstatioinsInboxDepartments &&
							!converstatioinsInboxDepartments.departments.some(
								(department) =>
									assignedToTeamsConversationFilter.find(
										(e) => {
											return (
												e.department == department &&
												converstatioinsInboxDepartments.inbox ==
													e.inboxId
											);
										}
									)
							)) ||
						assignedToTeamsConversationFilter.find(
							(e) =>
								e.department ==
								conversation.AssignedToDepartment
						)
					) {
						return true;
					}

					return false;
				})
				.sort((a, b) => {
					if (!a.LastMessage) return 1;
					if (!b.LastMessage) return -1;
					return new Date(b.LastMessage) - new Date(a.LastMessage);
				});
			setFilteredData(myFilteredData);
		}
	}

	const handleConversationChange = (conversation) => {
		$isMessageSending(false);
		$selectedConversation(conversation.ConversationId);
		$selectedFolder(conversation.FolderId);
		$selectedInbox(conversation.Folder.InboxId);
		ReactGA.event({
			category: "Navigation",
			action: "ConversationClicked",
		});
	};

	const removeConversation = (conversationId) => {
		setAllData(
			allData.filter(
				(conversation) => conversation.ConversationId !== conversationId
			)
		);
	};

	if (error && !allData) {
		return <p>Something went wrong...</p>;
	}

	if (loading) {
		return (
			<SpinnerContainer>
				<LoadingSpinner />
			</SpinnerContainer>
		);
	} else if (filteredData.length === 0) {
		return (
			<SpinnerContainer>
				<h3>No conversations</h3>
			</SpinnerContainer>
		);
	} else {
		return (
			<Container ref={containerRef}>
				<div
					style={{
						height: virtualizer.getTotalSize(),
						width: "100%",
						position: "relative",
					}}
				>
					{virtualizer.getVirtualItems().map((virtualItem) => {
						const conversation = filteredData[virtualItem.index];
						return (
							<div
								key={virtualItem.key}
								ref={virtualItem.measureElement}
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: "100%",
									transform: `translateY(${virtualItem.start}px)`,
								}}
							>
								<MessagePreview
									measure={(el) => {
										if (el) {
											const size =
												el.getBoundingClientRect()
													.height;
											measureItem(
												virtualItem.index,
												size
											);
										}
									}}
									isActive={
										currentConv ===
										conversation.ConversationId
									}
									onClick={async () => {
										handleConversationChange(conversation);
									}}
									key={conversation.ConversationId}
									conversationId={conversation.ConversationId}
									updatedOn={conversation.UpdatedOn}
									removeConversation={removeConversation}
									isRead={conversation.IsRead}
									lastMessage={
										conversation.defaultLastMesaage
									}
								/>
							</div>
						);
					})}
				</div>
				{/* </Infinite> */}
			</Container>
		);
	}
};

export default ConversationList;
