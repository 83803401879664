import { DownloadButton } from "./styles";
import useDownloadConversation from "./use-download-to-cvs";

const DownloadConversationButton = () => {
	const { downloadCSV } = useDownloadConversation();
	return (
		<DownloadButton
			data-tip="Download as CSV"
			style={{ float: "right" }}
			icon="fas fa-download"
			onClick={downloadCSV}
		/>
	);
};

export default DownloadConversationButton;
