import { useEffect, useState, useContext } from "react";
import ConversationList from "../../Components/ConversationList/conversation-list.component";
import MessageList from "../../Components/MessageList/message-list.component";
import SendMessage from "../../Components/SendMessage/send-message.component";
import ChatHeaderActions from "../../Widgets/ChatHeaderActions/chat-header-actions.widget";
import InfoPanel from "../../Components/InfoPanel/Info-panel.component";
import {
	MessageListWrapper,
	ChatWrapper,
	StyledInboxPage,
	InfoPanelWrapper,
	ChatSideWrapper,
} from "./styles";
import ConversationFilters from "../../Components/ConversationFilters/conversation-filters.component";
import SendTemplateAfter24Hours from "../../Components/24HoursPastTemplateMessage/send-template-after-24-hours.component";
import SendDeferredMessage from "../../Components/SendDeferredMessage/send-deferred-message.component";
import { differenceInMilliseconds } from "date-fns";
import { GET_LATEST_INBOUND_MESSAGE } from "./query";
import { useReactiveVar } from "@apollo/client";
import client from "../../Apollo";
import ReactTooltip from "react-tooltip";
import UnsubscribedMessage from "../../Components/UnsubscribedMessage/unsubscribed-message.component";
import {
	$selectedConversation,
	$selectedInbox,
	$selectedFolder,
	$infoPanelIsOpen,
} from "../../store";
import ReactGA from "react-ga4";
import WindowFocusHandler from "../../Components/WindowFocusHandler/WindowFocusHandler";
import { UserContext } from "../../Providers/user-provider";
import InboxSelector from "../../Components/InboxSelector/inbox-selector-component.js";
import NewNaturalChat from "../../Components/new-natural-chat/new-natural-chat";

const InboxPage = ({rootStore}) => {
	const user = useContext(UserContext);
	const [twentyFourHourVisible, setTwentyFourHourVisible] = useState(null);
	const [unsubscribedMessageVisible, setUnsubscribedMessageVisible] =
		useState(null);
	const now = new Date();
	const dayInMs = 86400000;
	const currentConv = useReactiveVar($selectedConversation);
	const selectedInbox = useReactiveVar($selectedInbox);
	const infoPanelIsOpen = useReactiveVar($infoPanelIsOpen);

	const loadMessageConditions = async () => {
		try {
			const response = await client.query({
				query: GET_LATEST_INBOUND_MESSAGE,
				variables: {
					conversationId: currentConv ? currentConv : 0,
				},
				fetchPolicy: "network-only",
			});

			const msg = response.data.msgbox_Message.map((message) => ({
				id: message.MessageId,
				datetime: message.DateTime,
				status: message.Conversation.Contact.Status,
			}));

			if (msg.length === 1) {
				const latestTime = msg[0].datetime;
				const status = msg[0].status;

				// Block Check
				if (status == "Unsubscribed") {
					setTwentyFourHourVisible(false);
					setUnsubscribedMessageVisible(true);
					return;
				}
				// Time Check
				if (
					differenceInMilliseconds(now, new Date(latestTime)) > dayInMs
				) {
					setTwentyFourHourVisible(true);
				} else {
					setTwentyFourHourVisible(false);
				}
				setUnsubscribedMessageVisible(false);
			} else {
				//default to closed
				setTwentyFourHourVisible(true);
			}
		} catch (error) {
			console.log("error", error);
		}
	};

	useEffect(() => {
		if (!("Notification" in window)) {
			// Notifications not supported
		} else {
			// Notifications supported
			//Notification.requestPermission();
		}

		// When ever the user "leaves" the inbox page set these vars to null
		// so that when they return the vars will be set based on the first retruned data
		return () => {
			console.log(
				"user leaving page, clearing down folderid, conversationid, and inboxid"
			);
			$selectedConversation(null);
			$selectedInbox(null);
			$selectedFolder(null);
		};
	}, []);

	useEffect(() => {
		if (infoPanelIsOpen === null){
			let should_be_open = localStorage.getItem("info_pannel")
			if(should_be_open === "closed") {
				$infoPanelIsOpen(false)
			} else {
				$infoPanelIsOpen(true)
				localStorage.setItem("info_pannel", "open")
			}
		}
	}, []);

	useEffect(() => {
		loadMessageConditions();
	});

	ReactGA.send({ hitType: "pageview", page: "/inbox" });
	return (
		<StyledInboxPage>
			<WindowFocusHandler />
			<MessageListWrapper>
				{/* <InboxSelector /> */}
				<ConversationFilters
				rootStore={rootStore}
				/>
				<ConversationList />
			</MessageListWrapper>
			<ChatSideWrapper>
			<ChatWrapper>
				<ChatHeaderActions />
				<MessageList loadMessageConditions={loadMessageConditions} />
				{twentyFourHourVisible ? (
					user.newNaturalChatConfig[selectedInbox]?.newNaturalChatText ? (
						<NewNaturalChat
						rootStore={rootStore}
						/>
					) : user.naturalchatConfig[selectedInbox] ? (
						<SendDeferredMessage rootStore={rootStore} />
					) : (
						<SendTemplateAfter24Hours
						rootStore={rootStore}
						/>
					)
				) : unsubscribedMessageVisible ? (
					<UnsubscribedMessage />
				) : (
					<SendMessage rootStore={rootStore}/>
				)}
			</ChatWrapper>
			{(infoPanelIsOpen === true && currentConv && currentConv !== -1) && (
				<InfoPanelWrapper>
					<InfoPanel />
				</InfoPanelWrapper>
			)}
			</ChatSideWrapper>
			<ReactTooltip className="tooltip" effect="solid" />
		</StyledInboxPage>
	);
};

export default InboxPage;
