import { useState, useEffect } from "react";
import { FieldArray, useFormikContext, Field } from "formik";
import Input from "../Input/input.component";
import FileUpload from "../FileUpload/file-upload.component";
import TextArea from "../TextArea/text-area.component";
import get from "lodash.get";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import Pill from "../Pill/pill.component";
import Button from "../Button/button.component";
import styled from "styled-components";
import { InputErrorMessage } from "../../Styles/global";
import client from "../../Apollo";

import {
	GET_TEMPLATE_MESSAGE_BY_NAME,
	GET_TEMPLATE_MESSAGE_BY_NAME_ACC_ID,
} from "../new-natural-chat/query";
import { NATURAL_CHAT_TEMPLATE_MEDIA_NAME } from "../new-natural-chat/constants";

const TagButton = styled(Button)`
	border: ${(props) =>
		props.primary ? "none" : `solid 1px ${props.theme.colours.light_4}`};
	height: 36px;
`;

const Step2 = ({ selectedInbox, selectedTemplate }) => {
	const [variableFieldStatus, setVariableFieldStatus] = useState(null);

	const [templateLength, setTemplateLength] = useState(0);

	const { setFieldValue, values, errors } = useFormikContext();
	const [mainText, setMainText] = useState("");
	const [charCounts, setCharCounts] = useState({});
	const [charLimits, setCharLimits] = useState({});

	const renderErrorMsg = (fieldName, errors, path) => {
		let error = get(errors, path);
		if (error) {
			error = fieldName + " " + error;
		}
		return error;
	};

	useEffect(() => {
		setFieldValue("attachments", []);
	}, []);

	useEffect(() => {}, [values.attachments]);

	const handleFileChange = (file) => {
		setFieldValue("attachments", file);
	};

	const renderButtonText = (valueToCheck) => {
		if (!variableFieldStatus) {
			return "Add tag";
		} else if (variableFieldStatus.valueToCheck === "tag") {
			return "Remove tag";
		} else {
			return "Remove tag";
		}
	};

	useEffect(() => {
		setMainText(values.templateMessages.value.text);
	}, [values.templateMessages.value.text]);

	useEffect(() => {
		let temp = values.templateMessages.value.text;
		values.variables.forEach((variable) => {
			if (variable.value.length > 0)
				temp = temp.replace(`{{${variable.label}}}`, variable.value);
		});
		setMainText(temp);
	}, [values]);

	useEffect(() => {
		const fetchTemplateLength = async () => {
			const { data } = await client.query({
				query: GET_TEMPLATE_MESSAGE_BY_NAME,
				variables: {
					name: selectedTemplate || NATURAL_CHAT_TEMPLATE_MEDIA_NAME,
					userInboxes: [selectedInbox],
				},
			});

			const template = data?.msgbox_Template?.[0];
			if (template) {
				setTemplateLength(template.TemplateText.length);
			}
		};

		fetchTemplateLength();
	}, [selectedTemplate, selectedInbox]);

	const handleChangeText = (event, index) => {
		const formattedText = event.target.value.replace(/\n/g, "");

		// removal of logic to handle character limiting (not working as desired but leaving for future reference)

		// console.log(values.variables.length, "< length");
		// const maxAllowed = Math.floor(
		// 	(1024 - templateLength) / values.variables.length
		// );

		// setCharCounts((prev) => ({
		// 	...prev,
		// 	[index]: formattedText.length,
		// }));
		// removal of logic to handle character limiting (not working as desired but leaving for future reference)

		// console.log(values.variables.length, "< length");
		// const maxAllowed = Math.floor(
		// 	(1024 - templateLength) / values.variables.length
		// );

		// setCharCounts((prev) => ({
		// 	...prev,
		// 	[index]: formattedText.length,
		// }));

		// setCharLimits((prev) => ({
		// 	...prev,
		// 	[index]: maxAllowed,
		// }));
		// setCharLimits((prev) => ({
		// 	...prev,
		// 	[index]: maxAllowed,
		// }));

		// if (formattedText.length >= maxAllowed) return;

		setFieldValue(`variables.${index}.value`, event.target.value);
	};

	return (
		<div>
			<div>
				<TextArea
					value={mainText}
					name="templateMessage"
					placeholder="Template Message"
					label="Template Message"
					readOnly
					disabled
				/>
				<FieldArray
					render={(helpers) => {
						return (
							values.variables.length > 0 &&
							values.variables.map((item, index) => (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "6px",
									}}
								>
									{variableFieldStatus &&
									variableFieldStatus[
										[`variables.${index}.value`]
									] === "tag" ? (
										<MsgBoxSelect
											options={[
												{
													label: "Contact Name",
													value: "contactname",
												},
												{
													label: "Salute As",
													value: "saluteas",
												},
												{
													label: "Organisation Name",
													value: "orgname",
												},
												{
													label: "Inbox Name",
													value: "inboxname",
												},
												{
													label: "My First Name",
													value: "firstname",
												},
												{
													label: "My Last Name",
													value: "lastname",
												},
												{
													label: "My Full Name",
													value: "fullname",
												},
											]}
											onChange={(options) => {
												setFieldValue(
													`variables.${index}.value`,
													options.value
												);
											}}
											name="tag"
											label={`Add dynamic variable for "${item.label}"`}
											error={renderErrorMsg(
												item.label,
												errors,
												`variables.${index}.value`
											)}
										/>
									) : (
										<>
											<div></div>
											<Field
												key={item.label}
												label={item.label}
												name={`variables.${index}.value`}
												placeholder={`Enter a value for "${item.label}"`}
												component={Input}
												onChange={(event) => {
													handleChangeText(
														event,
														index
													);
												}}
												error={renderErrorMsg(
													item.label,
													errors,
													`variables.${index}.value`
												)}
												value={
													values.variables[index]
														.value
												}
											/>
										</>
									)}

									<TagButton
										type="button"
										buttonName={
											variableFieldStatus &&
											variableFieldStatus[
												[`variables.${index}.value`]
											] === "tag"
												? "Remove variable"
												: "Add variable"
										}
										onClick={() => {
											let status;
											if (!variableFieldStatus) {
												status = "tag";
											} else if (
												variableFieldStatus[
													`variables.${index}.value`
												] === "tag"
											) {
												status = "text";
												setFieldValue(
													`variables.${index}.value`,
													""
												);
											} else {
												status = "tag";
											}
											setVariableFieldStatus({
												...variableFieldStatus,
												[`variables.${index}.value`]:
													status,
											});
										}}
									/>
								</div>
							))
						);
					}}
					name="variables"
				/>

				{values.templateMessages.value?.attachment &&
					values.templateMessages.value?.attachment !== "None" && (
						<div>
							<InputErrorMessage style={{ position: "relative" }}>
								{errors.attachments}
							</InputErrorMessage>
							<FileUpload
								label="Attachment*"
								handleFileChange={handleFileChange}
							/>
						</div>
					)}
			</div>
		</div>
	);
};

export default Step2;
