import React, { useEffect, useState } from "react";
import client from "../../Apollo";
import { GET_USER_INBOXES } from "../../Components/Layout/query";
import MsgBoxSelect from "../../Components/MsgBoxSelect/msg-box-select.component";
import { getUserId } from "../../helpers/functions";

const InboxSelect = (props) => {
	const [inboxes, setInboxes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		try {
			const getInboxes = async () => {
				const inboxesData = await client.query({
					query: GET_USER_INBOXES,
					variables: {
						userId: getUserId(),
					},
				});
				const allInboxes = [];
				inboxesData.data.msgbox_UserInbox.forEach((element) => {
					allInboxes.push({
						value: [element.Inbox.Name],
						label: element.Inbox.Name,
					});
				});
				allInboxes.sort((a, b) => a.label.localeCompare(b.label));
				allInboxes.unshift({
					value: inboxesData.data.msgbox_UserInbox.map(
						(element) => element.Inbox.Name
					),
					label: "All Inboxes",
				});


				if (props.setInbox) {
					props.setInbox(allInboxes);
				}
				setInboxes(allInboxes);
				setLoading(false);
			};
			getInboxes();
		} catch (error) {
			setError(true);
		}
	}, []);

	return (
		<MsgBoxSelect
			placeholder="Select Inbox"
			label="Select Inbox"
			options={inboxes}
			{...props}
		/>
	);
};

export default InboxSelect;
