import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_CONVERSATION } from "./query";
import { useReactiveVar } from "@apollo/client";
import { $selectedConversation, $selectedInbox } from "../../store";

const useDownloadConversation = () => {
	const selectedConversation = useReactiveVar($selectedConversation);

	const [getConversation, { loading, data }] = useLazyQuery(
		GET_CONVERSATION,
		{
			variables: { convoId: selectedConversation },
			fetchPolicy: "network-only",
		}
	);

	useEffect(() => {
		if (selectedConversation) {
			getConversation();
		}
	}, [selectedConversation]);

	const downloadCSV = () => {
		if (!data || !data.msgbox_Conversation) return;

		const conversation = data.msgbox_Conversation[0];
		if (!conversation || !conversation.Messages) return;

		const messages = conversation.Messages.map((msg) => [
			msg.DateTime, 
			conversation.ConversationUUID,
			conversation.Contact.Name, 
			conversation.Contact.MobileNumber,
			msg.User?.UserDepartments ? "outgoing" : "incoming", 
			(msg.User?.UserDepartments &&
				conversation.User.FirstName + conversation.User.LastName) ||
				" ", 
			msg && msg.BodyText ? msg.BodyText?.replace(/\n/g, " ") : "<media>", 
		]);

		const csvContent = [
			[
				"Timestamp",
				"ConversationID",
				"Contact Name",
				"Phone Number",
				"Direction",
				"Sender Name",
				"Message",
			],
			...messages,
		]
			.map((row) => row.map((cell) => `"${cell}"`).join(","))
			.join("\n");

		const blob = new Blob([csvContent], {
			type: "text/csv;charset=utf-8;",
		});
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.setAttribute(
			"download",
			`conversation_${conversation.ConversationId}.csv`
		);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return { loading, data, downloadCSV };
};

export default useDownloadConversation;
