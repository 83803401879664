import { useState } from "react";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { Formik } from "formik";
import { Container } from "./styles";
import Button from "../Button/button.component";
import { Prompt } from "react-router";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
import { GET_NOTIFICATIONS, UPDATE_NOTIFICATIONS } from "./query";
import { useQuery, useMutation } from "@apollo/client";
import { getUserId } from "../../helpers/functions";
import { useToasts } from "react-toast-notifications";
import { LoadingContainer } from "../AutomationSettings/styles";

const NotificationSettings = () => {
	const { data, error, loading } = useQuery(GET_NOTIFICATIONS, {
		variables: {
			userId: getUserId(),
		},
		fetchPolicy: "network-only",
	});

	const [
		updateNotificationsMutation,
		{ error: errorUpdating, loading: updatingNotification },
	] = useMutation(UPDATE_NOTIFICATIONS);

	const [dirty, setDirty] = useState(false);

	const { addToast } = useToasts();

	const submit = async (values) => {
		await updateNotificationsMutation({
			variables: {
				userId: getUserId(),
				notifyMyChats: values.notifyMyChats.value,
				notifyTeamChats: values.notifyTeamChats.value,
				notifyUnassignedChats: values.notifyUnassignedChats.value,
				notifyChatsAssignedMe: values.notifyChatsAssignedMe.value,
				browserNotifyChats: values.browserNotifyChats.value,
				mobileNotifyMyChats: values.mobileNotifyMyChats.value,
				mobileNotifyTeamChats: values.mobileNotifyTeamChats.value,
				NotifyIncludeContents: values.NotifyIncludeContents.value,
				mobileNotifyUnassignedChats:
					values.mobileNotifyUnassignedChats.value,
			},
		});
		setDirty(false);
		if (!errorUpdating) {
			addToast("Updated notification", {
				appearance: "success",
				autoDismiss: true,
			});
		}
	};

	if (loading)
		return (
			<LoadingContainer>
				<LoadingSpinner />
			</LoadingContainer>
		);
	if (error) return <p>oops... something went wrong</p>;

	return (
		<Container>
			<Formik
				initialValues={{
					notifyChatsAssignedMe: {
						label: data.msgbox_User[0].NotifyChatsAssignedMe
							? "ON"
							: "OFF",
						value: data.msgbox_User[0].NotifyChatsAssignedMe,
					},
					notifyUnassignedChats: {
						label: data.msgbox_User[0].NotifyUnassignedChats
							? "ON"
							: "OFF",
						value: data.msgbox_User[0].NotifyUnassignedChats,
					},
					notifyTeamChats: {
						label: data.msgbox_User[0].NotifyTeamChats
							? "ON"
							: "OFF",
						value: data.msgbox_User[0].NotifyTeamChats,
					},
					notifyMyChats: {
						label: data.msgbox_User[0].NotifyMyChats ? "ON" : "OFF",
						value: data.msgbox_User[0].NotifyMyChats,
					},
					browserNotifyChats: {
						label: data.msgbox_User[0].BrowserNotifyChats
							? "ON"
							: "OFF",
						value: data.msgbox_User[0].BrowserNotifyChats,
					},
					mobileNotifyMyChats: {
						label: data.msgbox_User[0].MobileNotifyMyChats
							? "ON"
							: "OFF",
						value: data.msgbox_User[0].MobileNotifyMyChats,
					},
					mobileNotifyTeamChats: {
						label: data.msgbox_User[0].MobileNotifyTeamChats
							? "ON"
							: "OFF",
						value: data.msgbox_User[0].MobileNotifyTeamChats,
					},
					mobileNotifyUnassignedChats: {
						label: data.msgbox_User[0].MobileNotifyUnassignedChats
							? "ON"
							: "OFF",
						value: data.msgbox_User[0].MobileNotifyUnassignedChats,
					},
					NotifyIncludeContents: {
						label: data.msgbox_User[0].NotifyIncludeContents
							? "ON"
							: "OFF",
						value: data.msgbox_User[0].NotifyIncludeContents,
					},
				}}
				onSubmit={(values) => {
					submit(values);
				}}
			>
				{({ handleSubmit, setFieldValue, values }) => (
					<form id="notifications-settings" onSubmit={handleSubmit}>
						<MsgBoxSelect
							options={[
								{
									label: "ON",
									value: true,
								},
								{
									label: "OFF",
									value: false,
								},
							]}
							onChange={(option) => {
								setFieldValue("notifyChatsAssignedMe", option);
								setDirty(true);
							}}
							name="notifyChatsAssignedMe"
							label="Email me when a chat is assigned to me or my team"
							value={values.notifyChatsAssignedMe}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "ON",
									value: true,
								},
								{
									label: "OFF",
									value: false,
								},
							]}
							onChange={(option) => {
								setFieldValue("notifyUnassignedChats", option);
								setDirty(true);
							}}
							name="notifyUnassignedChats"
							label="Email me when an unassigned chat is not responded to within 5 minutes"
							value={values.notifyUnassignedChats}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "ON",
									value: true,
								},
								{
									label: "OFF",
									value: false,
								},
							]}
							onChange={(option) => {
								setDirty(true);
								setFieldValue("notifyTeamChats", option);
							}}
							name="notifyTeamChats"
							label="Email me when chats assigned to my team(s) are not replied to after 5 min"
							value={values.notifyTeamChats}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "ON",
									value: true,
								},
								{
									label: "OFF",
									value: false,
								},
							]}
							onChange={(option) => {
								setDirty(true);
								setFieldValue("notifyMyChats", option);
							}}
							name="notifyMyChats"
							label="Email me when my chats are not replied to after 5 minutes"
							value={values.notifyMyChats}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "ON",
									value: true,
								},
								{
									label: "OFF",
									value: false,
								},
							]}
							onChange={(option) => {
								setDirty(true);
								setFieldValue("NotifyIncludeContents", option);
							}}
							name="NotifyIncludeContents"
							label="Include latest message in email alerts"
							value={values.NotifyIncludeContents}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "ON",
									value: true,
								},
								{
									label: "OFF",
									value: false,
								},
							]}
							onChange={(option) => {
								setDirty(true);
								setFieldValue("browserNotifyChats", option);
							}}
							name="browserNotifyChats"
							label="Notify me in the browser when new chats arrive for me or my team"
							value={values.browserNotifyChats}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "ON",
									value: true,
								},
								{
									label: "OFF",
									value: false,
								},
							]}
							onChange={(option) => {
								setDirty(true);
								setFieldValue("mobileNotifyMyChats", option);
							}}
							name="mobileNotifyMyChats"
							label="Notify me in the mobile app when new chats arrive for me"
							value={values.mobileNotifyMyChats}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "ON",
									value: true,
								},
								{
									label: "OFF",
									value: false,
								},
							]}
							onChange={(option) => {
								setDirty(true);
								setFieldValue("mobileNotifyTeamChats", option);
							}}
							name="mobileNotifyTeamChats"
							label="Notify me in the mobile app when new chats arrive for my team"
							value={values.mobileNotifyTeamChats}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "ON",
									value: true,
								},
								{
									label: "OFF",
									value: false,
								},
							]}
							onChange={(option) => {
								setDirty(true);
								setFieldValue(
									"mobileNotifyUnassignedChats",
									option
								);
							}}
							name="mobileNotifyUnassignedChats"
							label="Notify me in the mobile app when new chats arrive which are unassigned"
							value={values.mobileNotifyUnassignedChats}
						/>
						<Button
							style={{ float: "right" }}
							primary
							buttonName="Save"
							type="submit"
							loading={updatingNotification}
							disabled={dirty === false}
						/>
						<div style={{ clear: "both" }}></div>
					</form>
				)}
			</Formik>
			<Prompt
				when={dirty}
				message="You have unsaved changes, are you sure you want to leave?"
			/>
		</Container>
	);
};

export default NotificationSettings;
