import React from "react";
import {
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	LineChart,
	Tooltip,
	XAxis,
	YAxis,
	Line,
} from "recharts";
import { format } from "date-fns";
import { ChartContainer } from "./styles";
import { useTheme } from "styled-components";

const Chart = ({ chartData, chartDataKey, legendName, xAxisProps = {} }) => {
	const theme = useTheme();

	return (
		<ChartContainer>
			<ResponsiveContainer width="95%" height={500}>
				<LineChart>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						interval={0}
						dataKey="Date"
						type="category"
						{...xAxisProps}
					/>
					<YAxis dataKey="Count" name="Count" />
					<Legend verticalAlign="top" height={36} />
					<Tooltip
						formatter={(value, name, { payload }) => [
							`${value}`,
							name,
						]}
						labelFormatter={(label) => label}
					/>
					<Line
						name={legendName}
						type="monotone"
						dataKey="Count"
						stroke={theme.colours.primary}
						strokeWidth={2}
						data={chartData?.[chartDataKey] || []}
					/>
				</LineChart>
			</ResponsiveContainer>
		</ChartContainer>
	);
};

export default Chart;
