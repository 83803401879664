import styled from "styled-components";
import IconButton from "../IconButton/icon-button.component";

export const Container = styled.div`
	position: relative;
	margin: 6px;
`;

export const DownloadButton = styled(IconButton)`
	border: solid 1px ${(props) => props.theme.colours.light_3};
	font-size: 1.4rem;
	height: 38px;
	width: 38px;
	margin-left: 3px;
	border-radius: 5px;
	margin-right: 3px;
`;
