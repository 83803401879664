import { gql } from "@apollo/client";

export const GET_ALL_INBOXES = gql`
	query getInboxes {
		msgbox_Inbox {
			InboxId
			Name
		}
	}
`;

export const GET_INBOX_TEAM_COUNT = gql`
	query getInboxTeamCount($inboxId: Int!) {
		msgbox_DepartmentInbox(where: { InboxId: { _eq: $inboxId } }) {
			Department {
				Name
				DepartmentId
			}
		}
	}
`;

export const GET_RESELLER_NAME = gql`
	query getResellerName($orgId: Int!) {
		msgbox_Organisation(where: { OrganisationId: { _eq: $orgId } }) {
			Reseller {
				Brand
				Name
			}
		}
	}
`;
