import { gql } from "@apollo/client";

export const SEARCH_TAGS = gql`
	query searchTags($searchTerm: String!, $accountId: Int!) {
		msgbox_Tag(where: { _and: { Name: { _ilike: $searchTerm } } }) {
			Name
			TagId
			TagContacts_aggregate(
				where: {
					Contact: {
						IsDeleted: { _eq: false }
						_and: {
							APIAccount: { APIAccountId: { _eq: $accountId } }
						}
					}
					Tag: { Name: { _ilike: $searchTerm } }
				}
			) {
				aggregate {
					count
				}
			}

			TagContacts {
				Contact {
					ContactId
					Status
				}
				TagId
			}
		}
	}
`;

export const SEARCH_CONTACTS = gql`
	query searchContacts($searchTerm: String!, $accountId: Int!) {
		msgbox_Contact(
			where: {
				APIAccountId: { _eq: $accountId }
				# _and: { Name: { _ilike: $searchTerm } }
				_or: [
					{ Name: { _ilike: $searchTerm } }
					{ MobileNumber: { _ilike: $searchTerm } }
				]
			}
		) {
			Name
			ContactId
			MobileNumber
			Status
			APIAccount {
				APIAccountId
				Inboxes {
					InboxId
				}
			}
		}
	}
`;

export const SEARCH_TEMPLATE_MESSAGES = gql`
	query searchTemplateMessages(
		$apiAccountId: Int!
		$templateTypes: [String!]
	) {
		msgbox_Template(
			where: {
				APIAccount: { _eq: $apiAccountId }
				Status: { _eq: Approved }
				TemplateType: { Description: { _in: $templateTypes } }
			}
		) {
			Name
			TemplateText
			TemplateId
			Status
			Attachment
			TemplateType {
				Description
			}
		}
	}
`;

export const SEND_TEMPLATE_MESSAGE = gql`
	mutation sendTemplateMessage(
		$attachmentId: Int
		$Message: String
		$TemplateId: Int!
		$contacts: msgbox_BroadcastContact_arr_rel_insert_input!
		$account: Int!
		$followOnMessage: String
	) {
		insert_msgbox_Broadcast(
			objects: {
				Message: $Message
				TemplateId: $TemplateId
				BroadcastContacts: $contacts
				APIAccountId: $account
				AttachmentId: $attachmentId
				FollowOnMessage: $followOnMessage
			}
		) {
			affected_rows
			returning {
				Message
				BroadcastContacts {
					ContactId
				}
			}
		}
	}
`;

export const GET_CONVERSATIONS_IDS = gql`
	query getConversationsIds($contactIds: [Int]!) {
		msgbox_Conversation(where: { ContactId: { _in: $contactIds } }) {
			ConversationId
			ContactId
		}
	}
`;

export const SEND_DEFERRED_MESSAGE = gql`
	mutation sendDeferredMessage($Message: String, $conversationId: Int!) {
		insert_msgbox_Message(
			objects: {
				BodyText: $Message
				ConversationId: $conversationId
				DeferredDelivery: true
			}
		) {
			affected_rows
		}
	}
`;

export const SEND_NOT_DEFERRED_MESSAGE = gql`
	mutation sendNotDeferredMessage($Message: String, $conversationId: Int!) {
		insert_msgbox_Message(
			objects: {
				BodyText: $Message
				ConversationId: $conversationId
				DeferredDelivery: false
			}
		) {
			affected_rows
		}
	}
`;

export const GET_CONTACTS_WITH_TAG = gql`
	query getContacts($tags: [String!], $account: Int!) {
		msgbox_Contact(
			where: {
				TagContacts: {
					Tag: { Name: { _in: $tags } }
					Contact: { APIAccountId: { _eq: $account } }
				}
			}
		) {
			ContactId
			Status
			Name
		}
	}
`;

export const GET_PRESUBS_FROM_TAGS = gql`
	query getContactDetails($contactId: Int!) {
		msgbox_Contact(where: { ContactId: { _eq: $contactId } }) {
			ContactId
			TagContacts {
				TagId
				Tag {
					TagId
					Name
				}
			}
		}
	}
`;

export const DELETE_TAG_CONTACT = gql`
	mutation deleteTagContact($tagId: Int!, $contactId: Int!) {
		delete_msgbox_TagContact(
			where: { TagId: { _eq: $tagId }, ContactId: { _eq: $contactId } }
		) {
			affected_rows
			returning {
				TagId
				ContactId
			}
		}
	}
`;

export const GET_ORG_ID = gql`
	query getOrgId {
		msgbox_Organisation {
			OrganisationId
		}
	}
`;

export const GET_API_ACCOUNT_ID = gql`
	query getApiAccountId($inboxId: Int!) {
		msgbox_Inbox(where: { InboxId: { _eq: $inboxId } }) {
			APIAccountId
		}
	}
`;

export const CREATE_NEW_CONTACTS = gql`
	mutation CreateNewContacts($objects: [msgbox_Contact_insert_input!]!) {
		insert_msgbox_Contact(objects: $objects) {
			affected_rows
			returning {
				ContactId
			}
		}
	}
`;

export const GET_LATEST_TEMPLATES = gql`
	query GetLatestTemplates($ids: [Int!], $_gt: timestamptz = "") {
		msgbox_Message(
			where: {
				ConversationId: { _in: $ids }
				IsTemplate: { _eq: true }
				DateTime: { _gt: $_gt }
			}
		) {
			BroadcastContacts {
				ContactId
			}
			MessageId
		}
	}
`;

export const GET_LATEST_INBOUND_MESSAGES = gql`
	query getLatestInboundMessages($ids: [Int!], $_gt: timestamptz = "") {
		msgbox_Message(
			where: {
				ConversationId: { _in: $ids }
				SenderId: { _is_null: true }
				DateTime: { _gt: $_gt }
			}
			limit: 1
			order_by: { DateTime: desc }
		) {
			DateTime
			MessageId
			ConversationId
			Conversation {
				Contact {
					ContactId
					Status
				}
			}
		}
	}
`;

export const GET_INBOX_NATURAL_CHAT_1 = gql`
	query getNaturalChat1($accountId: Int!) {
		msgbox_UserInbox(
			where: { Inbox: { APIAccountId: { _eq: $accountId } } }
		) {
			Inbox {
				APIAccount {
					APIAccountId
					Templates(
						where: {
							Name: { _eq: "natural_chat" }
							Status: { _eq: Approved }
						}
					) {
						TemplateId
						TemplateText
					}
				}
				InboxId
				Name
			}
		}
	}
`;

export const GET_RESELLER_NAME = gql`
	query MyQuery($orgId: Int!) {
		msgbox_Organisation(where: { OrganisationId: { _eq: $orgId } }) {
			Reseller {
				Brand
				Name
			}
		}
	}
`;

export const CHECK_MIME_TYPE = gql`
	query MyQuery($attachmentId: Int!) {
		msgbox_Attachment(where: { AttachmentId: { _eq: $attachmentId } }) {
			MimeType
		}
	}
`;

export const GET_CONVERSATION_BY_CONTACT = gql`
	query MyQuery($contactId: [Int!]) {
		msgbox_Conversation(where: { ContactId: { _in: $contactId } }) {
			ConversationId
		}
	}
`;

export const GET_SUBSCRIBERS_CONFIG = gql`
	query getSubscribersConfig {
		msgbox_Configuration {
			ConfigurationId
			TreatPresubscribersAsSubscribers
		}
	}
`;
