import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useContext } from "react";
import { $isMessageSending, $selectedInbox } from "../../store";
import ButtonWithAbilityToSelect from "../ButtonWithAbilityToSelect/button-with-ability-to-select";
import { Container } from "../InputMessageWithPastingFiles/styles";
import {
	DEFAULT_ACCEPTED_IMAGE_FORMATS,
	useGlueJar,
} from "../SendDeferredMessage/gluejar";
import { UserContext } from "../../Providers/user-provider";

const NewNaturalChatInput = ({
	message,
	setMessage,
	addMessage,
	inputRef,
	handleMessageChange,
	handleInputClick,
	selectedConversation,
	isNaturalChatWindow = true,
	blockPastingFiles = false,
	setBlockFileSelection,
	charCount,
	charLimit,
}) => {
	const isMessageSending = useReactiveVar($isMessageSending);

	const { pasted, clearJar } = useGlueJar({ blockPastingFiles });
	const selectedInbox = useReactiveVar($selectedInbox);
	const user = useContext(UserContext);

	useEffect(() => {
		document.addEventListener("keypress", handleEnterKeyDown);
		return () => {
			document.removeEventListener("keypress", handleEnterKeyDown);
		};
	}, [message]);

	const handleEnterKeyDown = (event) => {
		if (message && selectedConversation) {
			if (event.shiftKey && event.key === "Enter") {
				return;
			} else if (event.key === "Enter") {
				if (document.activeElement === inputRef.current) {
					if (pasted.length <= 0) {
						addMessage();
					} else {
						addMessage(pasted[0], clearJar);
					}
				}
			}
		}
	};

	useEffect(() => {
		if (pasted && pasted.length > 0) {
			if (
				user.newNaturalChatConfig[selectedInbox].newNaturalChatMedia ===
				false
			) {
				clearJar();
				return;
			}
			setBlockFileSelection(true);
		} else {
			setBlockFileSelection(false);
		}
	}, [pasted]);

	useEffect(() => {
		if (pasted && pasted.length > 0) {
			clearJar();
		}
	}, [selectedConversation]);

	return (
		<Container>
			{pasted.length > 0 &&
				pasted.map((image) => (
					<div key={image.url} className="pasted-container">
						{DEFAULT_ACCEPTED_IMAGE_FORMATS.includes(
							image.file.type
						) ? (
							<img src={image.url} alt={`Pasted: ${image.url}`} />
						) : (
							<div>
								{image.file.type === "application/pdf" ? (
									<i className="fas fa-file-pdf"></i>
								) : (
									<i className="fas fa-file"></i>
								)}
								{image.file.name}
							</div>
						)}
						<button onClick={clearJar}>
							<i className="fas fa-trash"></i>
						</button>
					</div>
				))}
			<div className="text-row" style={{ gap: "10px" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
					}}
				>
					<textarea
						onClick={handleInputClick}
						onChange={handleMessageChange}
						type="text"
						ref={inputRef}
						placeholder={
							isNaturalChatWindow
								? "Type something to send... (this is the natural chat window)"
								: "Type something to send..."
						}
						value={message}
					/>
					<p style={{ marginLeft: "auto" }}>
						{charCount !== 0 ? charCount + "/" + charLimit : ""}
					</p>
				</div>
				<ButtonWithAbilityToSelect
					form={false}
					hasLabel={false}
					menuPlacement="top"
					height="25px"
					message={message}
					sendMessage={() => {
						if (pasted.length <= 0) {
							addMessage();
						} else {
							addMessage(pasted[0], clearJar);
						}
					}}
					loading={isMessageSending}
				/>
			</div>
		</Container>
	);
};

export default NewNaturalChatInput;
