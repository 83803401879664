export const failErrorCodes = [
	{
		code: "2#2",
		message: "The Meta service was temporarily unavailable",
	},
	{
		code: "100",
		message:
			"Action could not be completed by Meta. The object may not exist, you may not have permission to access it, or the request is not supported.",
	},
	{
		code: "100#100",
		message:
			"Action could not be completed. The message may be too long, missing content, or have bad parameters.",
	},
	{
		code: "470#470",
		message:
			"More than 24 hours have passed since the customer last replied, please send a template.",
	},
	{ code: "1000", message: "Video file exceeded the 64 MB limit" },
	{
		code: "1009",
		message: "The provided WhatsApp ID is not valid",
	},
	{
		code: "1013",
		message: "The recipient is not a valid WhatsApp user",
	},
	{
		code: "1014",
		message: "File size must be between 0 and 100 MB",
	},
	{
		code: "130472#130472",
		message:
			"Users phone number is part of an experiment and could not receive messages",
	},
	{
		code: "131008#131008",
		message: "A required field is missing",
	},
	{
		code: "131009#131009",
		message: "One of the provided values was not valid",
	},
	{
		code: "131026#131026",
		message: "The message could not be delivered",
	},
	{
		code: "131049#131049",
		message:
			"This message was not sent due to Meta policies on healthy engagement",
	},
	{
		code: "131053#131053",
		message:
			"Media upload error. Image format must be < 5mb JPEG or PNG. Video format must be < 16mb MP4",
	},
];

export const errorMapping = (error) => {
	try {
		if (error) {
			const matches = error.match(/\(#?\d+\)/g) || [];
			const code = matches
				.map((match) => match.replace(/\D/g, ""))
				.join("#");
			let match = failErrorCodes.find((errCode) => {
				return errCode.code === code;
			});
			let matchText = "";
			if (!match) {
				matchText = "META: " + error;
			} else {
				matchText = match.message;
			}
			return matchText;
		} else {
			return "Failed to Send Message";
		}
	} catch (exc) {
		console.log("error here:", exc);
	}
};
