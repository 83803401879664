import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_TEMPLATES_STATUS_BARCHART_DATA } from "./query";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import { TemplatesChartContainer } from "../styles";
import { useTheme } from "styled-components";

const TemplatesBarchart = ({ start, end, inboxes }) => {
	const [barchartData, setBarchartData] = useState([]);
	const theme = useTheme();

	const { error, loading } = useQuery(GET_TEMPLATES_STATUS_BARCHART_DATA, {
		variables: {
			start,
			end,
			inboxes,
		},
		fetchPolicy: "no-cache",
		onCompleted: ({ reporting_TemplatesSentByInbox }) => {
			const templateMap = new Map();

			reporting_TemplatesSentByInbox.forEach((template) => {
				const existingData = templateMap.get(template.Name) || {
					templateName: template.Name,
					Total: 0,
				};
				existingData.Total += template.SentCount;
				templateMap.set(template.Name, existingData);
			});

			const output = Array.from(templateMap.values()).map((item) => ({
				...item,
				Total: item.Total === 0 ? 0.1 : item.Total,
			}));

			output.sort((a, b) => a.templateName.localeCompare(b.templateName));
			setBarchartData(output);
		},
	});

	if (error) return null;
	if (loading) return null;
	if (barchartData.length === 0) return null;

	return (
		<TemplatesChartContainer>
			<div style={{ width: "100%", height: "100%" }}>
				<ResponsiveContainer width="100%" height="100%">
					<BarChart data={barchartData}>
						<CartesianGrid />
						<XAxis dataKey="templateName" />
						<YAxis
							domain={[0, (dataMax) => Math.max(dataMax, 5.0)]}
						/>
						<Tooltip
							formatter={(value) => (value === 0.1 ? "0" : value)}
						/>
						<Legend verticalAlign="top" height={36} />
						<Bar
							dataKey="Total"
							fill={theme.colours.primary}
							name="Total Sent"
						/>
					</BarChart>
				</ResponsiveContainer>
			</div>
		</TemplatesChartContainer>
	);
};

export default TemplatesBarchart;
