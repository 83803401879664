import { gql } from "@apollo/client";

export const GET_OUTBOUND_MESSAGE_TABLE_DATA = gql`
	query getOutboundMessageTableData(
		$start: timestamptz
		$end: timestamptz
		$inboxes: [String]
	) {
		msgbox_Message(
			where: {
				IsFailed: { _eq: false }
				SenderId: { _is_null: false }
				CreatedOn: { _gt: $start, _lt: $end }
				BroadcastContacts: {
					Broadcast: {
						APIAccount: { Inboxes: { Name: { _in: $inboxes } } }
					}
				}
			}
			order_by: { User: { UserId: asc } }
		) {
			User {
				FirstName
				LastName
				UserId
			}
			CreatedOn
		}
	}
`;

export const GET_TEMPLATES_TABLE_DATA = gql`
	query getTemplatesTableData(
		$start: timestamptz
		$end: timestamptz
		$inboxes: [String]
	) {
		msgbox_BroadcastContact(
			where: {
				CreatedOn: { _gt: $start, _lt: $end }
				Status: { _in: ["Delivered", "Read"] }
				Broadcast: { APIAccount: { Name: { _in: $inboxes } } }
			}
		) {
			User {
				FirstName
				LastName
				UserId
			}
		}
	}
`;

export const GET_TEMPLATES_SENT_BY_DATE_DATA = gql`
	query getTemplatesSentByDate(
		$start: date
		$end: date
		$inboxes: [String!]
	) {
		reporting_TemplatesSentByInbox(
			where: {
				Date: { _gte: $start, _lte: $end }
				Inbox: { _in: $inboxes }
			}
			order_by: { Date: asc }
		) {
			Date
			Count
		}
	}
`;

export const GET_CONTACTS_BARCHART_DATA = gql`
	query getContactsBarChartData(
		$start: timestamptz
		$end: timestamptz
		$inboxes: [String]
	) {
		msgbox_Inbox(where: { Name: { _in: $inboxes } }) {
			InboxId
			Name
			APIAccount {
				Contacts(where: { CreatedOn: { _gt: $start, _lt: $end } }) {
					ContactId
					CreatedOn
					Status
				}
			}
		}
	}
`;

export const GET_TEMPLATES_BARCHART_DATA = gql`
	query getTemplatesBarchartData(
		$start: timestamptz
		$end: timestamptz
		$inboxes: [String!]!
	) {
		msgbox_BroadcastContact(
			where: {
				Contact: {
					APIAccount: { Inboxes: { Name: { _in: $inboxes } } }
				}
				Message: { IsTemplate: { _eq: true }, IsFailed: { _eq: false } }
				CreatedOn: { _gte: $start, _lte: $end }
			}
		) {
			Status
			Broadcast {
				APIAccount {
					Inboxes {
						InboxId
						Name
					}
				}
				Template {
					TemplateId
					Name
				}
			}
		}
	}
`;

export const GET_TEMPLATES_STATUS_BARCHART_DATA = gql`
	query getTemplatesStatusBarchartData(
		$start: date
		$end: date
		$inboxes: [String]
	) {
		reporting_TemplatesSentByInbox(
			where: {
				Date: { _gte: $start, _lte: $end }
				Inbox: { _in: $inboxes }
			}
		) {
			Count
			Date
			DeliveredCount
			FailedCount
			RepliedCount
			Name
			OrganisationId
			ReadCount
			SentCount
		}
	}
`;
