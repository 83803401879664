import { gql } from "@apollo/client";

export const GET_USER_EMAIL = gql`
	query getEmail($userId: Int!) {
		msgbox_User(where: { UserId: { _eq: $userId } }) {
			EMail
			UserId
		}
	}
`;

export const MASK_NUMBERS = gql`
	query canSeeNumbers( $userId: Int) {
	msgbox_UserRole(where: {UserId: {_eq: $userId}}){
		Role {
		Name
		}
	}
	msgbox_UserInbox(where: {UserId: {_eq: $userId}}) {
			Inbox {
				InboxId
				maskPhoneNumbers
			}
		}
	}
`
export const SET_USER_EMAIL = gql`
	mutation setUserEmail($userId: Int!, $email: String!) {
		update_msgbox_User(
			where: { UserId: { _eq: $userId } }
			_set: { EMail: $email }
		) {
			affected_rows
			returning {
				UserId
				EMail
			}
		}
	}
`;

export const GET_USERS_BRAND = gql`
	query getUsersBrand($userId: Int!) {
		msgbox_User(where: { UserId: { _eq: $userId } }) {
			userorganisation {
				Name
				Reseller {
					Brand
				}
			}
		}
	}
`;

export const GET_USER_INBOXES = gql`
	query getUserInboxes($userId: Int!) {
		msgbox_UserInbox(where: { UserId: { _eq: $userId } }) {
			UserId
			InboxId
			Inbox	{
				Folders {
					FolderId
					Name
				}
			}
		}
	}
`;

export const GET_USER_ROLE = gql`
	query getUserRole($userId: Int!) {
		msgbox_User(where: { UserId: { _eq: $userId } }) {
			UserId
			UserRoles {
				Role {
					Name
					RoleId
				}
			}
		}
	}
`;
