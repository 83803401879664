import styled from "styled-components";

export const Container = styled.div`
	margin: 24px;
`;

export const AnalyticsFiltersContainer = styled.div`
	display: flex;
	gap: 6px;
	width: 40%;
	padding-left: 12px;
	padding-right: 12px;
	background-color: ${(props) => props.theme.colours.light_0};
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	border-radius: 6px;
	margin-left: 12px;
`;

export const StyledDataCard = styled.div`
	background-color: ${(props) => props.theme.colours.light_0};
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	border-radius: 6px;
	margin: 12px;
	flex: 1;
	min-height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	outline: ${(props) =>
		props.selected && `solid 2px ${props.theme.colours.primary}`};
	&:hover {
		background-color: ${(props) => props.theme.colours.light_2};
	}
`;

export const DataContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	padding: 20px;
	width: 100%;
`;

export const ChartContainer = styled.div`
	flex: 1 1 calc(50% - 10px);
	min-width: 400px;
	background: ${(props) => props.theme.colours.light_0};
	border-radius: 6px;

	padding: 20px;
	display: flex;
	flex-direction: column;
`;

export const TemplatesChartContainer = styled(ChartContainer)`
	min-height: 500px;
	height: 500px;
	display: flex;
	flex-direction: column;
`;

export const Header = styled.div`
	text-align: left;
	margin-left: 12px;
	font-size: 22px;
	font-weight: 400;
	color: black;
`;

// export const UpgradeMessage = styled.div`
// 	background-color: white;
// 	padding: 16px 20px;
// 	width: 70%;
// 	border-radius: 8px;
// 	font-size: 16px;
// 	color: #333;
// 	margin: 20px 0;
// 	display: flex;
// 	flex-direction: row;
// 	gap: 4px;
// 	margin-left: 10px;

// 	strong {
// 		color: #007bff;
// 	}

// 	a {
// 		text-decoration: underline;
// 		font-weight: bold;
// 		cursor: pointer;
// 	}
// `;

export const UpgradeMessage = styled.div`
	background-color: white;
	padding: 16px 20px;
	border-radius: 8px;
	margin: 20px 0;
	width: 70%;
	color: #ff0000;
	margin-left: 10px;
`;

export const CenteredSpinnerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh; /* full viewport height */
	width: 100vw; /* full viewport width */
	position: fixed;
	top: 0;
	left: 0;
`;
