import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_TEMPLATES_STATUS_BARCHART_DATA } from "./query";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import { TemplatesChartContainer } from "../styles";
import { useTheme } from "styled-components";

const TemplatesStatusBarchart = ({ start, end, inboxes }) => {
	console.log("Component props:", { start, end, inboxes });
	const [barchartData, setBarchartData] = useState([]);
	const theme = useTheme();

	const { error, loading } = useQuery(GET_TEMPLATES_STATUS_BARCHART_DATA, {
		variables: {
			start,
			end,
			inboxes,
		},
		skip: !start || !end || !inboxes,
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			console.log("Query completed with data:", data);
			if (!data || !data.reporting_TemplatesSentByInbox) {
				console.log("No data in query response");
				return;
			}

			const { reporting_TemplatesSentByInbox } = data;
			console.log("Raw data:", reporting_TemplatesSentByInbox);

			if (reporting_TemplatesSentByInbox.length === 0) {
				console.log("Empty data array");
				setBarchartData([
					{
						name: "Templates",
						Sent: 0.1,
						Delivered: 0.1,
						Failed: 0.1,
						Read: 0.1,
						Replied: 0.1,
					},
				]);
				return;
			}

			const aggregatedData = reporting_TemplatesSentByInbox.reduce(
				(acc, template) => ({
					Sent: (acc.Sent || 0) + (template.SentCount || 0),
					Delivered:
						(acc.Delivered || 0) + (template.DeliveredCount || 0),
					Failed: (acc.Failed || 0) + (template.FailedCount || 0),
					Read: (acc.Read || 0) + (template.ReadCount || 0),
					Replied: (acc.Replied || 0) + (template.RepliedCount || 0),
				}),
				{}
			);
			console.log("Aggregated data:", aggregatedData);

			const processedData = {
				name: "Templates",
				Sent: aggregatedData.Sent === 0 ? 0.1 : aggregatedData.Sent,
				Delivered:
					aggregatedData.Delivered === 0
						? 0.1
						: aggregatedData.Delivered,
				Failed:
					aggregatedData.Failed === 0 ? 0.1 : aggregatedData.Failed,
				Read: aggregatedData.Read === 0 ? 0.1 : aggregatedData.Read,
				Replied:
					aggregatedData.Replied === 0 ? 0.1 : aggregatedData.Replied,
			};
			console.log("Processed data:", processedData);

			setBarchartData([processedData]);
		},
	});

	console.log("Component state:", { error, loading, barchartData });

	if (!start || !end || !inboxes) {
		console.log("Missing required props");
		return null;
	}
	if (error) {
		console.log("Error:", error);
		return null;
	}
	if (loading) {
		console.log("Loading...");
		return null;
	}
	if (barchartData.length === 0) {
		console.log("No data");
		return null;
	}

	console.log("Rendering with data:", barchartData);

	return (
		<TemplatesChartContainer>
			<div
				style={{
					width: "100%",
					flex: 1,
					minHeight: 0,
				}}
			>
				<ResponsiveContainer width="100%" height="100%">
					<BarChart data={barchartData}>
						<CartesianGrid />
						<XAxis dataKey="name" />
						<YAxis
							domain={[0, (dataMax) => Math.max(dataMax, 5.0)]}
						/>
						<Tooltip
							formatter={(value) => (value === 0.1 ? "0" : value)}
						/>
						<Legend verticalAlign="top" height={36} />
						<Bar
							dataKey="Sent"
							fill={theme.colours.primary}
							name="Total Sent"
						/>
						<Bar
							dataKey="Delivered"
							fill="#2F855A"
							name="Total Delivered"
						/>
						<Bar
							dataKey="Failed"
							fill="#C53030"
							name="Total Failed"
						/>
						<Bar dataKey="Read" fill="#6B46C1" name="Total Read" />
						<Bar
							dataKey="Replied"
							fill="#DD6B20"
							name="Total Replied"
						/>
					</BarChart>
				</ResponsiveContainer>
			</div>
		</TemplatesChartContainer>
	);
};

export default TemplatesStatusBarchart;
