import { Container } from "./styles";
import { Link } from "react-router-dom";
import { UserContext } from "../../Providers/user-provider";
import { useContext } from "react";

const SettingsSideNav = () => {
	const userContext = useContext(UserContext);
	return (
		<Container>
			<div className="settings-header">
				<h2>Settings</h2>
			</div>
			<hr></hr>
			<ul>
				<li>
					<i className="fas fa-user-circle"></i>
					<Link to="/settings/profile">Profile</Link>
				</li>
				<hr></hr>
				<li>
					<i className="fas fa-bell"></i>
					<Link to="/settings/notifications">Notifications</Link>
				</li>
				<hr />

				{userContext.role === "orgadmin" && (
					<>
						<li>
							<i className="fas fa-clock"></i>
							<Link to="/settings/opening-hours">
								Opening hours
							</Link>
						</li>
						<hr></hr>
						<li>
							<i className="fas fa-tags"></i>
							<Link to="/settings/tags">Tags</Link>
						</li>
						<hr></hr>
						<li>
							<i className="far fa-hand-paper"></i>
							<Link to="/settings/subscribers">Subscribers</Link>
						</li>
						<hr></hr>
						<li>
							<i className="fas fa-user"></i>
							<Link to="/settings/users">Users</Link>
						</li>
						<hr></hr>
						<li>
							<i className="fas fa-users"></i>
							<Link to="/settings/teams">Teams</Link>
						</li>
						<hr></hr>
						<li>
							<i className="fas fa-robot"></i>
							<Link to="/settings/automation">Automation</Link>
						</li>
						<hr></hr>

						<li>
							<i className="fas fa-lock"></i>
							<Link to="/settings/permissions">Permissions</Link>
						</li>
						<hr></hr>

						<li>
							<i className="fas fa-puzzle-piece"></i>
							<Link to="/settings/chrome-extension">
								Chrome Extension
							</Link>
						</li>
						<hr></hr>
						<li>
							<i className="fas fa-envelope"></i>
							<Link to="/settings/email-parser">
								Email Parser
							</Link>
						</li>
						<hr></hr>
						<li>
							<i className="fas fa-bolt"></i>
							<Link to="/settings/webhooks">Webhooks</Link>
						</li>
					</>
				)}
			</ul>
		</Container>
	);
};

export default SettingsSideNav;
