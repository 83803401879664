import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_TEMPLATES_SENT_BY_DATE_DATA } from "./query";
import Chart from "../../../Components/Chart/chart";
import { NoData } from "./no-data";

const SentTemplatesChart = ({ start, end, inboxes }) => {
	const [chartData, setChartData] = useState(null);

	const { error, loading } = useQuery(GET_TEMPLATES_SENT_BY_DATE_DATA, {
		variables: {
			start,
			end,
			inboxes,
		},
		fetchPolicy: "no-cache",
		onCompleted: ({ reporting_TemplatesSentByInbox }) => {
			const dateMap = new Map();

			reporting_TemplatesSentByInbox.forEach((item) => {
				const date = item.Date;
				const existingData = dateMap.get(date) || {
					Date: date,
					Count: 0,
				};
				existingData.Count += parseInt(item.Count) || 0;
				dateMap.set(date, existingData);
			});

			const aggregatedData = {
				reporting_TemplatesSentByInbox: Array.from(
					dateMap.values()
				).sort((a, b) => new Date(a.Date) - new Date(b.Date)),
			};

			setChartData(aggregatedData);
		},
	});

	if (error) return null;
	if (loading) return null;
	if (!chartData?.reporting_TemplatesSentByInbox[0])
		return <NoData>No Data</NoData>;

	return (
		<Chart
			chartData={chartData}
			chartDataKey="reporting_TemplatesSentByInbox"
			legendName="Templates Sent"
			xAxisProps={{
				angle: -45,
				textAnchor: "end",
				height: 100,
			}}
		/>
	);
};

export default SentTemplatesChart;
