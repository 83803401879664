import React, { useState } from "react";
import { FormField, msgBoxSelectButtonCustomStyles } from "../../Styles/global";
import { useTheme } from "styled-components";
import Select from "react-select";
import {
	SelectButton,
	Container,
	IconButton,
	ButtonsWrapper,
	LoadingWrapper,
} from "./styles";
import useArchiveConversation from "../ArchiveButton/use-archive-conversation";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
const sendOptions = [
	{
		value: "SEND",
		label: "Send",
	},
	{
		value: "SEND_ARCHIVE",
		label: "Send & Archive",
	},
];
const ButtonWithAbilityToSelect = ({
	label,
	required,
	error,
	hasTooltip,
	toolTipText,
	table,
	hasLabel,
	form,
	sendMessage,
	loading,
	message,
	...rest
}) => {
	const selectRef = React.useRef();
	const msgBoxTheme = useTheme();
	const archiveConversation = useArchiveConversation();

	const [selectedSendOption, setSelectedSendOption] = useState(
		sendOptions[0]
	);

	const onClick = () => {
		if (selectRef.current) {
			selectRef.current.focus();
		}
	};

	const handleSendMessage = () => {
		sendMessage();
		if (selectedSendOption.value === "SEND_ARCHIVE") {
			archiveConversation();
		}
	};

	const isDisabled = () => {
		return loading || !message?.trim();
	};

	return (
		<FormField isForm={form} table={table}>
			<Container>
				<Select
					openMenuOnFocus={true}
					ref={selectRef}
					styles={msgBoxSelectButtonCustomStyles(msgBoxTheme)}
					theme={(theme) => ({
						...theme,
						colors: {
							...theme.colors,
							primary25: msgBoxTheme.colours.primaryLight,
							primary: msgBoxTheme.colours.primary,
						},
					})}
					value={selectedSendOption}
					onChange={(option) => setSelectedSendOption(option)}
					options={sendOptions}
					{...rest}
				/>
				<ButtonsWrapper>
					<SelectButton
						onClick={handleSendMessage}
						disabled={loading}
					>
						{loading ? (
							<LoadingWrapper>
								<LoadingSpinner />
							</LoadingWrapper>
						) : (
							selectedSendOption.label
						)}
					</SelectButton>
					<IconButton disabled={loading} onClick={onClick}>
						<i className="fas fa-chevron-down"></i>
					</IconButton>
				</ButtonsWrapper>
			</Container>
		</FormField>
	);
};

export default ButtonWithAbilityToSelect;
