import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
	SEND_TEMPLATE_MESSAGE,
	GET_CONTACTS_WITH_TAG,
	CREATE_NEW_CONTACTS,
	GET_CONVERSATIONS_IDS,
	GET_LATEST_INBOUND_MESSAGES,
	SEND_NOT_DEFERRED_MESSAGE,
	GET_RESELLER_NAME,
	CHECK_MIME_TYPE,
	GET_CONVERSATION_BY_CONTACT,
} from "./query";
import {
	ADD_MESSAGE_TO_CONVERSATION_DEFERRED,
	GET_TEMPLATE_MESSAGE_BY_NAME,
} from "../new-natural-chat/query";
import { Formik } from "formik";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import Stepper from "../Stepper/stepper.component";
import Step1 from "./send-template-message-step1.component";
import Step2 from "./send-template-message-step2.component";
import client from "../../Apollo";
import { useToasts } from "react-toast-notifications";
import { getAccessToken } from "../../helpers/functions";
import Modal from "react-modal";
import {
	ModalDescription,
	ModalHeader,
	ModalContentSeperator,
} from "../MsgBoxModal/styles";
import ReactGA from "react-ga4";
import useCurrentUserDetails from "../../helpers/use-current-user-details";
import { generateValidationScheme } from "./valiadtaion-schema";
import { $isMessageSending } from "../../store";
import Tags from "../TagsFormField/tags.component";
import MessageSendingProgressModal from "../MessageSendingProgressModal/MessageSendingProgressModal";
import {
	NATURAL_CHAT_TEMPLATE_TEXT_NAME,
	NATURAL_CHAT_TEMPLATE_MEDIA_NAME,
} from "../new-natural-chat/constants";
import { $selectedConversation } from "../../store";
import ConvertingModal from "../ConvertingModal/convertingModal";

const SendTemplateMessage = ({
	rootStore,
	isOpen,
	close,
	disableSearchBy,
	contacts,
	disabledFields,
	account,
	table,
	showPendingMessage = false,
}) => {
	const { currentUser } = useCurrentUserDetails();
	const [addContacts] = useMutation(CREATE_NEW_CONTACTS);
	const [sendMessage, { error }] = useMutation(SEND_TEMPLATE_MESSAGE);
	const [addMessageMutation, { error: messageMutationError, loading }] =
		useMutation(ADD_MESSAGE_TO_CONVERSATION_DEFERRED);

	const [checkMimeType] = useLazyQuery(CHECK_MIME_TYPE);
	const [getConversationId] = useLazyQuery(GET_CONVERSATION_BY_CONTACT);

	const [sendNotDeferredMessage] = useMutation(SEND_NOT_DEFERRED_MESSAGE);
	const [naturalChatTemplate, setNaturalChatTemplate] = useState(null);
	const [newNaturalChatTemplate, setNewNaturalChatTemplate] = useState(null);
	const [submitClicked, setSubmitClicked] = useState(false);
	const [maxBroadcasts, setMaxBroadcasts] = useState(0);
	const [preSubAsSub, setPreSubAsSub] = useState(false);
	const [preSubCount, setPreSubCount] = useState(0);
	const [showSendingProgressModal, setShowSendingProgressModal] =
		useState(false);
	const [tagsContactsCount, setTagsContactsCount] = useState(0);
	const [recipientState, setRecipientsState] = useState();
	const [converting, setConverting] = useState(false);
	const [converted, setIsConverted] = useState();
	const TIMEOUT_DURATION = 1200000;
	const selectedConversation = useReactiveVar($selectedConversation);
	const [selectedInbox, setSelectedInbox] = useState(null);
	const [selectedTemplate, setSelectedTemplate] = useState(null);

	const orgId = localStorage.getItem("org_id");
	const [resellerName, setResellerName] = useState("");
	const [getResellerName] = useLazyQuery(GET_RESELLER_NAME);

	const [currentStep, setCurrentStep] = useState(1);
	const [isSent, setIsSent] = useState(false);
	const { addToast } = useToasts();
	const [disableSubmit, setDisableSubmit] = useState(false);

	useEffect(() => {
		fetchResellerName();
	}, [orgId]);

	const fetchResellerName = async () => {
		let result = await getResellerName({
			variables: {
				orgId: orgId,
			},
		});
		setResellerName(result.data.msgbox_Organisation[0].Reseller.Name);
	};

	async function checkAttachment(
		attachmentId,
		recipients,
		templateId,
		templateMessage,
		accountId,
		isVideoTemplate
	) {
		setConverting(true);
		const startTime = Date.now();
		let contactIdArray = recipients.map((recipient) => {
			return recipient.ContactId;
		});
		const conversationIdArray = await getConversationId({
			variables: {
				contactId: contactIdArray,
			},
		});
		async function retryQuery() {
			if (Date.now() - startTime >= TIMEOUT_DURATION) {
				setConverting(false);
				console.warn("Request timed out.");
				return;
			}
			const data = await checkMimeType({
				variables: { attachmentId },
				fetchPolicy: "no-cache",
			});
			//conversion complete on back end
			if (data.data.msgbox_Attachment[0].MimeType === "video/mp4") {
				//freeform/nat chat video message
				if (isVideoTemplate === false) {
					//first send initial 'ok' message
					await sendMessage({
						variables: {
							TemplateId: templateId,
							Message: templateMessage,
							account: accountId,
							contacts: {
								data: recipients.map((x) => {
									return {
										ContactId: x.ContactId,
									};
								}),
							},
						},
					});
					//send deferred attachments to all conversationIds
					conversationIdArray.data.msgbox_Conversation.map(
						async (conversation) => {
							await addMessageMutation({
								variables: {
									bodyText: "",
									conversationId: conversation.ConversationId,
									attachmentId: attachmentId,
								},
							});
						}
					);
					setIsConverted(true);
					setConverting(false);
					addToast("Messages sent successfully", {
						appearance: "success",
						autoDismiss: true,
					});
				}
				//specific video template message
				else {
					await sendMessage({
						variables: {
							attachmentId,
							TemplateId: templateId,
							Message: templateMessage,
							// If the account field is disabled / hidden use the account prop
							account: accountId,
							contacts: {
								data: recipients,
							},
						},
					});
					setIsConverted(true);
					setConverting(false);
					addToast("Messages sent successfully", {
						appearance: "success",
						autoDismiss: true,
					});
				}
			} else {
				console.log("retrying.. ");
				setTimeout(retryQuery, 1000); // Retry after 1 second
			}
		}
		retryQuery();
	}

	const sendOwnMessage = async (values, actions, recipients) => {
		try {
			if (values?.attachments?.length > 0) {
				let attachmentId = await uploadAttachment(
					values.attachments[0]
				);
				if (!attachmentId) return;
				else {
					let templateName = NATURAL_CHAT_TEMPLATE_MEDIA_NAME;

					const getNaturalChatMediaTemplate = await client.query({
						query: GET_TEMPLATE_MESSAGE_BY_NAME,
						variables: {
							name: templateName,
							userInboxes: [values.account.inboxId],
						},
					});

					const templateText =
						getNaturalChatMediaTemplate.data.msgbox_Template[0]
							.TemplateText;
					const templateMessage = templateText.replace(
						"{{1}}",
						values.message
					);
					const templateId =
						getNaturalChatMediaTemplate.data.msgbox_Template[0]
							.TemplateId;
					const accountId = values.account.value;

					//if non mp4 video, convert
					if (values?.attachments[0]?.type?.includes("video")) {
						//checks *and* sends
						checkAttachment(
							attachmentId,
							recipients,
							templateId,
							templateMessage,
							accountId,
							false
						);
					} else {
						//Just send, no need to check attachment mime type
						await sendMessage({
							variables: {
								TemplateId: templateId,
								Message: templateMessage,
								account: accountId,
								contacts: {
									data: recipients.map((x) => {
										return {
											ContactId: x.ContactId,
										};
									}),
								},
							},
						});

						let contactIdArray = recipients.map((recipient) => {
							return recipient.ContactId;
						});
						const conversationIdArray = await getConversationId({
							variables: {
								contactId: contactIdArray,
							},
						});
						conversationIdArray.data.msgbox_Conversation.map(
							async (conversation) => {
								await addMessageMutation({
									variables: {
										bodyText: "",
										conversationId:
											conversation.ConversationId,
										attachmentId: attachmentId,
									},
								});
								addToast("Messages sent successfully", {
									appearance: "success",
									autoDismiss: true,
								});
							}
						);
					}
				}
			} else if (newNaturalChatTemplate) {
				const templateText = newNaturalChatTemplate.TemplateText;
				const templateMessage = templateText.replace(
					"{{1}}",
					values.message
				);

				await sendMessage({
					variables: {
						attachmentId: null,
						TemplateId: newNaturalChatTemplate.TemplateId,
						Message: templateMessage,
						account: disabledFields.account
							? account
							: values.account.value,
						contacts: {
							data: recipients.map((x) => {
								return {
									ContactId: x.ContactId,
								};
							}),
						},
					},
				});
				addToast("Messages sent successfully", {
					appearance: "success",
					autoDismiss: true,
				});
			} else {
				const templateText = naturalChatTemplate.TemplateText;

				const msg = templateText.replace(
					"{{1}}",
					currentUser.FirstName
				);
				// get conversations for contacts
				const { data: conversationIdsData } = await client.query({
					query: GET_CONVERSATIONS_IDS,
					variables: {
						contactIds: recipients.map((rec) => rec.ContactId),
					},
				});

				const contactsWithoutConversations = recipients.filter(
					(recipient) =>
						!conversationIdsData.msgbox_Conversation
							.map((x) => x.ContactId)
							.includes(recipient.ContactId)
				);
				if (contactsWithoutConversations.length > 0) {
					await sendMessage({
						variables: {
							attachmentId: null,
							TemplateId: naturalChatTemplate.TemplateId,
							Message: msg,
							account: disabledFields.account
								? account
								: values.account.value,
							contacts: {
								data: contactsWithoutConversations,
							},
							followOnMessage: values.message,
						},
					});
				}
				if (conversationIdsData.msgbox_Conversation.length > 0) {
					let tempDate = new Date();
					tempDate.setDate(tempDate.getDate() - 1);
					const { data: getLatestInboundMessage } =
						await client.query({
							query: GET_LATEST_INBOUND_MESSAGES,
							variables: {
								ids: conversationIdsData.msgbox_Conversation.map(
									(x) => x.ConversationId
								),
								_gt: tempDate,
							},
						});

					// conversations inside 24h window
					if (getLatestInboundMessage.msgbox_Message.length > 0) {
						const promises =
							getLatestInboundMessage.msgbox_Message.map((x) => {
								sendNotDeferredMessage({
									variables: {
										Message: values.message,
										conversationId: x.ConversationId,
									},
								});
							});
						await Promise.all(promises);
					}

					const conversationsOutside24h =
						conversationIdsData.msgbox_Conversation.filter(
							(convData) =>
								!getLatestInboundMessage.msgbox_Message
									.map((x) => x.ConversationId)
									.includes(convData.ConversationId)
						);
					if (conversationsOutside24h.length > 0) {
						await sendMessage({
							variables: {
								attachmentId: null,
								TemplateId: naturalChatTemplate.TemplateId,
								Message: msg,
								account: disabledFields.account
									? account
									: values.account.value,
								contacts: {
									data: conversationsOutside24h.map((x) => {
										return {
											ContactId: x.ContactId,
										};
									}),
								},
								followOnMessage: values.message,
							},
						});

						addToast("Messages sent successfully", {
							appearance: "success",
							autoDismiss: true,
						});
					}
				}
			}

			actions.resetForm();
			close();

			if (table) {
				table.current.refetchData();
			}
			return;
		} catch (e) {
			console.log("error", error);
			addToast("Error sending messages", {
				appearance: "error",
				autoDismiss: true,
			});
		}
	};

	const handleCloseModal = () => {
		setShowSendingProgressModal(false);
	};

	const onSubmit = async (values, actions) => {
		setDisableSubmit(true);
		setRecipientsState(values.contacts);

		setIsSent(false);

		let addedContactsArray = [];
		const contactsToBeAdded = values.contacts.filter(
			(x) => x.status === "NEW_CONTACT"
		);
		if (contactsToBeAdded.length > 0) {
			const { data: addedContacts } = await addContacts({
				variables: {
					objects: contactsToBeAdded.map((x) => {
						return {
							Name: x.value.trim().replace(" ", ""),
							MobileNumber: x.value.trim().replace(" ", ""),
							Status: "Presubscribed",
							APIAccountId: values.account.value,
						};
					}),
				},
			});

			addedContactsArray =
				addedContacts.insert_msgbox_Contact.returning.map((x) => {
					return { ContactId: x.ContactId };
				});
		}
		const alreadyExistingContacts = values.contacts.filter(
			(x) => x.status !== "NEW_CONTACT"
		);
		let recipients = [];
		let attachmentId = null;
		// using form with ability to select contacts
		if (contacts.length <= 0) {
			if (values.searchBy === "search-contacts") {
				// already existing and new contacts here
				recipients = alreadyExistingContacts.map((contact) => ({
					ContactId: contact.value,
				}));
				if (addedContactsArray.length > 0) {
					recipients = [...recipients, ...addedContactsArray];
				}
			} else {
				// send message based on the contacts tag
				// Fetch all contacts that have selected tags.
				const tags = values.tags.map((tag) =>
					tag.label.replace(/ *\([^)]*\) */g, "")
				);
				const contactsWithTags = await client.query({
					query: GET_CONTACTS_WITH_TAG,
					variables: {
						tags,
						account: values.account.value,
					},
				});

				if (preSubAsSub === false) {
					let unSubscribed = 0;
					recipients = contactsWithTags.data.msgbox_Contact
						.map((contact) => {
							if (contact.Status !== "Subscribed") unSubscribed++;
							if (
								contact.Status !== "Subscribed" &&
								unSubscribed > 10
							)
								return null;
							return {
								ContactId: contact.ContactId,
							};
						})
						.filter((item) => item);
				} else {
					recipients = contactsWithTags.data.msgbox_Contact.map(
						(contact) => {
							return {
								ContactId: contact.ContactId,
							};
						}
					);
				}
			}
		} else {
			recipients = contacts.map((contact) => ({
				ContactId: contact.value,
			}));
		}
		if (values.message && values.message.length > 0) {
			if (
				!/\n/.test(values.message) &&
				(naturalChatTemplate || newNaturalChatTemplate)
			) {
				await sendOwnMessage(values, actions, recipients);
			}
			setDisableSubmit(false);
			return;
		}
		if (values?.attachments?.length > 0) {
			attachmentId = await uploadAttachment(values.attachments[0]);
			if (!attachmentId) return;
		}
		let message = values.templateMessages.value.text;
		if (values.variables.length > 0) {
			message = values.templateMessages.value.text;
			values.variables.forEach((variable) => {
				if (
					variable.value === "contactname" ||
					variable.value === "saluteas" ||
					variable.value === "firstname" ||
					variable.value === "lastname" ||
					variable.value === "orgname" ||
					variable.value === "inboxname" ||
					variable.value === "fullname"
				) {
					message = message.replace(
						`{{${variable.label}}}`,
						`{{${variable.value}}}`
					);
				} else {
					message = message.replace(
						`{{${variable.label}}}`,
						variable.value
					);
				}
			});
			setSubmitClicked(false);
		}

		if (values?.attachments[0]?.type?.includes("video")) {
			let acc = disabledFields.account ? account : values.account.value;
			await checkAttachment(
				attachmentId,
				recipients,
				values.templateMessages.value.templateId,
				message,
				acc,
				true
			);
		} else {
			await sendMessage({
				variables: {
					attachmentId,
					TemplateId: values.templateMessages.value.templateId,
					Message: message,
					// If the account field is disabled / hidden use the account prop
					account: disabledFields.account
						? account
						: values.account.value,
					contacts: {
						data: recipients,
					},
				},
			});
			addToast("Templates sent successfully", {
				appearance: "success",
				autoDismiss: true,
			});
		}

		setDisableSubmit(false);
		if (!error) {
			actions.resetForm();
			setIsSent(true);
			close();
			// addToast("Templates sent successfully", {
			// 	appearance: "success",
			// 	autoDismiss: true,
			// });
			if (showPendingMessage) {
				$isMessageSending(true);
			}
			if (table) {
				table.current.refetchData();
			}
			ReactGA.event({
				category: "Messages",
				action: "SentTemplateMessage",
			});
		} else {
			console.log("error", error);
			addToast("Error sending messages", {
				appearance: "error",
				autoDismiss: true,
			});
			ReactGA.event({
				category: "Messages",
				action: "FailedSendTemplateMessage",
			});
		}
	};

	const uploadAttachment = async (file) => {
		const { name, type } = file;
		try {
			const token = await getAccessToken();

			const signedUrlResponse = await fetch(
				`${process.env.REACT_APP_API_URL}filessignedurl`,
				{
					method: "POST",
					headers: {
						"Content-Disposition": `filename=${name}`,
						Authorization: `Bearer ${token}`,
						"Content-Type": type,
					},
				}
			);

			const { attachmentId, presignedUrl } =
				await signedUrlResponse.json();

			const attachmentResponse = await fetch(presignedUrl, {
				method: "PUT",
				headers: {
					"Content-Disposition": `filename=${name}`,
					"Content-Type": type,
				},
				body: file,
			});

			if (
				attachmentResponse.status === 200 ||
				attachmentResponse.status === 201
			) {
				return attachmentId;
			} else {
				return false;
			}
		} catch (error) {
			console.log("error", error);
		}
	};

	/**
	 * Determines if we need to render step 2 - if there are no variables or attachments dont render step 2
	 */
	const getSteps = (values) => {
		let steps = [
			{
				title: "Filters",
				component: (
					<Step1
						disabledFields={disabledFields}
						disableSearchBy={disableSearchBy}
						apiAccountIdProp={account}
						setNaturalChatTemplate={setNaturalChatTemplate}
						naturalChatTemplate={naturalChatTemplate}
						setNewNaturalChatTemplate={setNewNaturalChatTemplate}
						newNaturalChatTemplate={newNaturalChatTemplate}
						rootStore={rootStore}
						maxBroadcasts={maxBroadcasts}
						setMaxBroadcasts={setMaxBroadcasts}
						preSubAsSub={preSubAsSub}
						setPreSubAsSub={setPreSubAsSub}
						setPreSubCount={setPreSubCount}
						setTagsContactsCount={setTagsContactsCount}
						setSelectedInbox={setSelectedInbox}
						selectedInbox={selectedInbox}
						selectedTemplate={selectedTemplate}
						setSelectedTemplate={setSelectedTemplate}
					/>
				),
			},
			{
				title: "Variables",
				component: (
					<Step2
						disabledFields={disabledFields}
						selectedInbox={selectedInbox}
						selectedTemplate={selectedTemplate}
					/>
				),
			},
		];
		// If there is an attachment => render step 2
		if (
			!values.templateMessages.value?.attachment ||
			values.templateMessages.value?.attachment === "None"
		) {
			// If there is no attachemnt, check if there is variable fields.
			const regex = /[^{{\}]+(?=}})/g;
			const results = values.templateMessages.value?.text.match(regex);
			if (!results) {
				steps.splice(1, 1);
			}
		}

		return steps;
	};

	const handleStepChange = (currentStep) => {
		setCurrentStep(currentStep);
	};

	const renderTitle = () => {
		switch (currentStep) {
			case 1:
				return "Send Template Message";

			case 2:
				return "Variables ";

			default:
				break;
		}
	};

	const renderDescription = () => {
		switch (currentStep) {
			case 1:
				return "Use the form below to send a template message.";

			case 2:
				return "Enter the necessary variable values once you are happy click submit to send.";
			default:
				break;
		}
	};

	return (
		<>
			<ConvertingModal isOpen={converting}></ConvertingModal>

			<Formik
				validationSchema={
					generateValidationScheme(
						disabledFields,
						naturalChatTemplate,
						newNaturalChatTemplate,
						maxBroadcasts,
						preSubAsSub,
						preSubCount,
						tagsContactsCount,
						resellerName
					)[currentStep - 1]
				}
				validateOnBlur={false}
				validateOnChange={false}
				initialValues={{
					tags: [],
					contacts: contacts,
					templateMessages: "",
					variables: [],
					attachments: [],
					templateMessage: "",
					searchBy: "search-contacts",
					account: account,
					message: "",
				}}
				onSubmit={(values, actions) => {
					onSubmit(values, actions);
				}}
			>
				{({ handleSubmit, values, validateForm, resetForm }) => (
					<Modal
						aria={{
							labelledby: "add_template_message_heading",
							describedby:
								"add_template_message_full_description",
						}}
						isOpen={isOpen}
						onRequestClose={() => {
							close();
							resetForm();
						}}
						style={{
							content: {
								overflow: "visible",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
							},
							overlay: {
								backgroundColor: "rgba(0,0,0,0.7)",
							},
						}}
					>
						<form
							id="send_template_message"
							onSubmit={handleSubmit}
						>
							<ModalHeader>{renderTitle()}</ModalHeader>
							<ModalDescription>
								{renderDescription()}
							</ModalDescription>
							<ModalContentSeperator />
							<Stepper
								validate={validateForm}
								onStepChange={handleStepChange}
								steps={getSteps(values)}
								closeModal={() => {
									close();
									resetForm();
								}}
								hasMessage={values.message.length > 0}
								disableSubmit={disableSubmit}
							/>
						</form>
					</Modal>
				)}
			</Formik>
		</>
	);
};

SendTemplateMessage.defaultProps = {
	disableSearchBy: false,
	contacts: [],
	disabledFields: {
		searchBy: false,
		contacts: false,
		tags: false,
		templateMessages: false,
		account: false,
	},
	table: null,
};

SendTemplateMessage.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	/**
	 * Removes the ability to change the "search by" field. Needed because in the contacts page we prepopulate contacts.
	 */
	disableSearchBy: PropTypes.bool,
	contacts: PropTypes.array,
	disabledFields: PropTypes.object,
};

export default SendTemplateMessage;
