import styled from "styled-components";

export const SiteSelectionPage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 20px;
	background-color: #f8f9fa;
	input {
		outline: none;
	}
	button {
		outline: none;
	}
`;

export const TableContainer = styled.div`
	width: 100%;
	max-width: 900px;
	background: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	margin-bottom: 20px;
`;

export const CustomUrlSection = styled.div`
	width: 100%;
	max-width: 900px;
	background: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const AddCustomUrl = styled.div`
	display: flex;
	gap: 10px;
	margin-bottom: 15px;
`;

export const CustomUrlInput = styled.input`
	flex: 1;
	padding: 10px;
	border: 1px solid #ced4da;
	border-radius: 5px;
	font-size: 14px;
	max-height: 35px;
`;

export const UrlList = styled.ul`
	list-style-type: none;
	padding: 0;
`;

export const UrlItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #e9ecef;
	padding: 2px;
	border-radius: 5px;
	margin-bottom: 8px;
`;

export const AddUrlButton = styled.button`
	padding: 2px 12px;
	background: #28a745;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 12px;
	transition: background 0.3s;
	&:hover {
		background: #28a745;
	}
`;

export const RemoveButton = styled.button`
	padding: 6px 12px;
	background: #dc3545;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 12px;
	transition: background 0.3s;
	&:hover {
		background: #a71d2a;
	}
`;

export const SaveButton = styled.button`
	float: right;
	background: #28a745;
	margin-top: 20px;
	padding: 8px 20px;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background 0.3s;

	&:hover {
		background: #218838;
	}

	display: flex;
	flex-direction: column;
	align-items: center;
`;
