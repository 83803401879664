import React, { useState, useRef, useEffect, useCallback } from "react";
import MsgBoxTable from "../MsgBoxTable/msg-box-table.component";
import {
	GET_ALL_CE_ALLOWED_SITES,
	GET_CE_CONFIG,
	GET_CUSTOM_SITES,
	ADD_SITE_CE_CONFIG,
	DELETE_ORG_CE_CONFIG,
	ADD_CUSTOM_SITES,
	DELETE_CUSTOM_SITES,
} from "./query";
import { getApolloContext, useLazyQuery, useMutation } from "@apollo/client";
import Pill from "../Pill/pill.component";
import {
	SiteSelectionPage,
	TableContainer,
	CustomUrlSection,
	AddCustomUrl,
	CustomUrlInput,
	AddUrlButton,
	UrlList,
	UrlItem,
	RemoveButton,
	SaveButton,
} from "./styles";
import { useToasts } from "react-toast-notifications";
import { Prompt } from "react-router";
import { margin } from "polished";

const ChromeExtensionSettings = () => {
	const [sites, setSites] = useState([]);
	const [customUrls, setCustomUrls] = useState([]);
	const [selectedSites, setSelectedSites] = useState([]);
	const [newCustomUrl, setNewCustomUrl] = useState("");
	const [config, setConfig] = useState([]);
	const [orgId, setOrgId] = useState(null);
	const tableRef = useRef();
	const { addToast } = useToasts();
	const [errorText, setErrorText] = useState("");
	const [dirty, setDirty] = useState(false);

	const [getAllowedSites] = useLazyQuery(GET_ALL_CE_ALLOWED_SITES);
	const [getConfig] = useLazyQuery(GET_CE_CONFIG);
	const [addConfig] = useMutation(ADD_SITE_CE_CONFIG, {
		refetchQueries: [
			{ query: GET_ALL_CE_ALLOWED_SITES },
			{ query: GET_CE_CONFIG },
		],
	});
	const [deleteConfig] = useMutation(DELETE_ORG_CE_CONFIG, {
		refetchQueries: [
			{ query: GET_ALL_CE_ALLOWED_SITES },
			{ query: GET_CE_CONFIG },
		],
	});
	const [getCustomSites] = useLazyQuery(GET_CUSTOM_SITES);
	const [addCustomSites] = useMutation(ADD_CUSTOM_SITES, {
		refetchQueries: [{ query: GET_CUSTOM_SITES }],
	});
	const [deleteCustomSites] = useMutation(DELETE_CUSTOM_SITES, {
		refetchQueries: [{ query: GET_CUSTOM_SITES }],
	});

	useEffect(() => {
		setOrgId(localStorage.getItem("org_id"));
	}, []);

	const fetchData = useCallback(async () => {
		try {
			const { data: allSitesData } = await getAllowedSites();
			const allSites = allSitesData?.msgbox_CEAllowedSites || [];
			const { data: customSitesData } = await getCustomSites();
			const customSites = customSitesData?.msgbox_CECustomConfig || [];

			customSites.forEach((customSite) => {
				setCustomUrls((prev) => [...prev, customSite.URLs]);
				console.log(customSite.URLs, "< in custom sites for each ");
				// const exists = allSites.some((site) =>
				// 	site.URLs.includes(customSite.URLs)
				// );
				// if (!exists) {
				// 	console.log("in custom sites for each IF CHECK");
				// 	console.log(customSite, "< custom site");
				// 	allSites.push({
				// 		CEAllowedSiteId: customSite.CECustomConfigId,
				// 		Name: "Custom Site",
				// 		URLs: customSite.URLs,
				// 	});
				// }
			});

			const { data: configData } = await getConfig();
			const config = configData?.msgbox_CEConfig || [];

			if (!Array.isArray(config)) {
				throw new Error("config is not an array! Check API response.");
			}

			setConfig(config);
			const activeSiteIds = config.map((item) => item.CEAllowedSiteId);
			setSites(
				allSites?.map((site) => ({
					...site,
					active: activeSiteIds.includes(site.CEAllowedSiteId),
				}))
			);
			setSelectedSites(activeSiteIds);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}, [getAllowedSites, getCustomSites, getConfig, addConfig, deleteConfig]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const formatData = () => {
		return sites.map((site) => ({
			CEAllowedSiteId: site.CEAllowedSiteId,
			Name: site.Name,
			URLs: site.URLs,
			active: config.some(
				(c) =>
					c.CEAllowedSiteId === site.CEAllowedSiteId &&
					c.OrganisationId === orgId
			),
		}));
	};

	const handleAddCustomUrl = () => {
		if (validateUrl(newCustomUrl)) {
			setDirty(true);
			setErrorText("");
			setCustomUrls([...customUrls, newCustomUrl]);
			setNewCustomUrl("");
		} else {
			setErrorText("Please enter a valid URL");
		}
	};

	const handleRemoveCustomUrl = (url) => {
		setCustomUrls(customUrls.filter((existingUrl) => existingUrl !== url));
		setDirty(true);
	};

	const validateUrl = (url) => {
		const regex = /^(https:\/\/)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
		return regex.test(url);
	};

	async function handleSelect(row) {
		const siteId = row.CEAllowedSiteId;
		const isCurrentlySelected = checkSelected(row);
		setDirty(true);
		setSelectedSites((prev) =>
			isCurrentlySelected
				? prev.filter((id) => id !== siteId)
				: [...prev, siteId]
		);
	}

	function checkSelected(row) {
		return selectedSites.includes(row.CEAllowedSiteId);
	}

	const handleSaveConfig = async () => {
		if (dirty) {
			try {
				await deleteConfig({
					variables: {
						orgId: orgId,
					},
				});
				selectedSites.map(async (site) => {
					await addConfig({
						variables: {
							siteId: site,
						},
					});
				});

				await deleteCustomSites({
					variables: {
						orgId: orgId,
					},
				});
				customUrls.map(async (customUrl) => {
					await addCustomSites({
						variables: {
							urls: customUrl,
						},
					});
				});

				console.log(customUrls, "custom urls");

				setDirty(false);
				addToast("Configuration updated successfully", {
					appearance: "success",
					autoDismiss: true,
				});
			} catch (error) {
				console.error("Error updating config:", error);
				addToast("An error occurred while updating the configuration", {
					appearance: "error",
					autoDismiss: true,
				});
			}
		}
	};

	const columns = [
		{
			name: "Active",
			selector: "Checkbox",
			cell: (row) => (
				<input
					type="checkbox"
					onChange={() => {
						handleSelect(row);
					}}
					checked={checkSelected(row)}
				/>
			),
			grow: 0,
		},
		{ name: "Name", selector: "Name", grow: 1 },
		{ name: "URLs", selector: "URLs", grow: 1 },
	];

	return (
		<>
			<SiteSelectionPage>
				{sites.length > 0 && (
					<TableContainer>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								margin: "10px ",
								gap: "10px",
							}}
						>
							<h4
								style={{
									color: "black",
									display: "flex",
									justifyContent: "start",
								}}
							>
								Allowed Sites
							</h4>
							<p
								style={{
									fontSize: "12px",
								}}
							>
								Please note these settings can take up to one
								hour to take effect, and only apply when you are
								logged into the chrome extension
							</p>
						</div>
						<MsgBoxTable
							heading=""
							searchPlaceHolder="Search by site name..."
							ref={tableRef}
							columns={columns}
							data={sites}
							fetchRows={GET_ALL_CE_ALLOWED_SITES}
							defaultLimit={10}
							formatData={formatData}
							defaultOrderBy={{
								field: "Name",
								sortDirection: "asc",
							}}
							hasAddButton={false}
							hasSearch={false}
							hasDelete={false}
						/>
					</TableContainer>
				)}
				<CustomUrlSection>
					<h4 style={{ color: "black", padding: 10 }}>
						Allowed Custom URLs
					</h4>
					<AddCustomUrl>
						<CustomUrlInput
							type="text"
							value={newCustomUrl}
							onChange={(e) => setNewCustomUrl(e.target.value)}
							placeholder="Enter custom URL (https://...)"
						/>
						<AddUrlButton onClick={handleAddCustomUrl}>
							Add URL
						</AddUrlButton>
					</AddCustomUrl>

					{errorText !== "" && (
						<p style={{ color: "red", justifySelf: "center" }}>
							{errorText}
						</p>
					)}
					<UrlList>
						{customUrls.map((url, index) => (
							<UrlItem key={index}>
								{url}{" "}
								<RemoveButton
									onClick={() => handleRemoveCustomUrl(url)}
								>
									Remove
								</RemoveButton>
							</UrlItem>
						))}
					</UrlList>
				</CustomUrlSection>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-end",
						maxWidth: "900px",
						width: "100%",
						padding: "10px",
						backgroundColor: "#f8f9fa",
					}}
				>
					<SaveButton onClick={handleSaveConfig}>Save</SaveButton>
				</div>
			</SiteSelectionPage>
			<Prompt
				when={dirty}
				message="You have unsaved changes, are you sure you want to leave?"
			/>
		</>
	);
};

export default ChromeExtensionSettings;
