import { gql } from "@apollo/client";

export const GET_ALL_CE_ALLOWED_SITES = gql`
	query msgbox_CEAllowedSites {
		msgbox_CEAllowedSites(where: {}, order_by: { Name: asc }) {
			CEAllowedSiteId
			Name
			URLs
		}
	}
`;
export const GET_CE_CONFIG = gql`
	query msgbox_CEConfig {
		msgbox_CEConfig {
			CEAllowedSiteId
			OrganisationId
		}
	}
`;

export const ADD_CUSTOM_SITES = gql`
	mutation addCustomSites($urls: String!) {
		insert_msgbox_CECustomConfig(objects: { URLs: $urls }) {
			affected_rows
		}
	}
`;

export const GET_CUSTOM_SITES = gql`
	query getCustomSites {
		msgbox_CECustomConfig {
			CECustomConfigId
			URLs
		}
	}
`;

export const DELETE_CUSTOM_SITES = gql`
	mutation deleteCustomSites($orgId: Int!) {
		delete_msgbox_CECustomConfig(
			where: { OrganisationId: { _eq: $orgId } }
		) {
			affected_rows
		}
	}
`;

export const ADD_SITE_CE_CONFIG = gql`
	mutation addSiteCEConfig($siteId: Int!) {
		insert_msgbox_CEConfig(objects: { CEAllowedSiteId: $siteId }) {
			affected_rows
		}
	}
`;

export const DELETE_ORG_CE_CONFIG = gql`
	mutation deleteOrgCEConfig($orgId: Int!) {
		delete_msgbox_CEConfig(where: { OrganisationId: { _eq: $orgId } }) {
			affected_rows
		}
	}
`;
