import styled from "styled-components";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { getRelativePath } from "mobx-state-tree";

export const Send = styled.i`
	color: ${(props) => props.theme.colours.primary};
`;

export const Fail = styled.i`
	color: ${(props) => props.theme.colours.danger};
`;

export const TooltipContainer = styled.span`
	position: relative;
	display: inline-block;

	.tooltiptext {
		visibility: hidden;
		width: 120px;
		background-color: black;
		color: #fff;
		text-align: center;
		padding: 5px 0;
		border-radius: 6px;
		position: absolute;
		z-index: 1;
		bottom: 125%; /* Position above */
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}

	&:hover .tooltiptext {
		visibility: visible;
		opacity: 1;
	}
`;
